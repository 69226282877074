.checkoutCart {
  align-items: flex-end;
}

.makeOrderCss {
  margin: 0 0 45px;
}

.clearAllBody {
  padding: 20px;
  font-size: 18px;
  display: flex;
  justify-content: space-evenly;
  color: #008cba;
}

.clearAllClose {
  padding: 15px 15px 0px;
}
