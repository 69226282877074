.login__block__row {
  display: flex;
}

.login__block__row .login__block__col {
  width: 33.33%;
  padding: 0 12px;
  margin-bottom: 30px;
}

.login__block__row .login__col__botton {
  width: 70%;
}

.loginbutton i {
  font-size: 75px;
  margin-bottom: 4px;
}

.loginbutton img {
  width: 79px;
  margin-bottom: 4px;
}
@media (max-width: 1440px) { 
  .register-top-block {
    min-height: 185px;
  }
}

@media (max-width: 1230px) {
  .register-top-block {
    min-height: 200px;
  }

  .login__block__row .login__col__botton {
    width: 90%;
  }

  .register-main-title {
    min-height: 48px;
  }

  .loginbutton {
    padding: 15px 10px;
  }

  .loginbutton i {
    font-size: 32px;
  }

  .loginbutton img {
    width: 35px;
  }
}

@media (max-width: 991px) {
  .login__block__row .login__block__col {
    width: 100%;
  }

  .register-main-title {
    min-height: auto;
  }

  .register-top-block {
    min-height: auto;
  }
}

@media (max-width: 430px) {
  
  .login__block__row .login__col__botton {
    width: 100%;
  }

  .loginbutton {
    padding: 15px 10px;
    min-height: 108px;
  }
}