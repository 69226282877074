.shopregister__container {
    margin-top: 86px;
    padding: 30px 0;
}

.shopregister__section {
    display: flex;
}

.shopregister__section .shopregister__form {
    width: 34%;
}

.shopregister__section .shopregister__content {
    width: 66%;
}

.teams__block {
    display: flex;
    gap: 10px;
    align-items: flex-start;
}

.teams__block input {
    margin-top: 7px;
}

.teams__block .terms-and-condition {
    margin: 0px;
}

@media (max-width: 1440px) { 
    .shopregister__section .shopregister__form {
        width: 38%;
    }
    
    .shopregister__section .shopregister__content {
        width: 62%;
    }
}

@media (max-width: 1030px) {
    .shopregister__container {
        margin-top: 138px;
    }

    .shopregister__section .shopregister__form,
    .shopregister__section .shopregister__content {
        width: 50%;
    }
}

@media (max-width: 991px) {
    .shopregister__container {
        margin-top: 154px;
    }
}
  
@media (max-width: 580px) {
    .shopregister__section .shopregister__form,
    .shopregister__section .shopregister__content {
        width: 100%;
    }
}