.register__section {
  margin-top: 82px;
}

.individual-register {
  width: 35%;
  padding: 50px 0px;
  margin: 0 auto;
}

.individual-register .form-control {
  border-radius: 0px;
  font-size: 14px;
}

@media (max-width: 1440px) {
  .individual-register {
    width: 40%;
  }
}

@media (max-width: 1230px) {
  .register__section {
    margin-top: 140px;
  }
  .individual-register {
    width: 50%;
  }
}

@media (max-width: 991px) {
  .individual-register {
    width: 60%;
  }
}

@media (max-width: 580px) {
  .register__section {
    margin-top: 160px;
  }

  .individual-register {
    width: 90%;
  }
}
