/* @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

*{
  font-family: 'Bebas Neue', cursive;
} */

@import url("https://fonts.googleapis.com/css2?family=Lexend&display=swap");

* {
  font-family: "Lexend";
}


