.main_inner {
  margin: 5rem auto 10rem;
  padding: 2rem 0;
}

.empty_cart_title {
  margin: 50px auto 0;
  text-align: center;
  padding: 18px 0;
}
.empty_cart_title h1 {
  text-transform: uppercase;
  color: #0da5e8;
  text-align: center;
  font-size: 25px;
  line-height: normal;
}
.empty_cart_wrap {
  margin: 0 auto;
  text-align: center;
  display: table;
}
.empty_cart_wrap p.label {
  color: #333;
  font-size: 20px;
  font-weight: 400;
  padding: 0 0 15px;
  display: block;
}
.empty_cart_wrap p {
  font-size: 16px;
  line-height: 1.8em;
  margin: 0;
}
.lablel {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
.title_divider {
  background: none repeat scroll 0 0 #cecece;
  height: 1px;
  overflow: hidden;
  margin: 15px auto 0;
  width: 85%;
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .empty_cart_title h1 {
    margin: 2rem auto 0;
    font-size: 20px;
  }
  .empty_cart_wrap p.label {
    font-size: 15px;
    padding: 0 0 10px;
  }
  .empty_cart_wrap {
    width: 100%;
  }
  .empty_cart_wrap p {
    font-size: 13px;
    line-height: 1.5em;
  }
}
