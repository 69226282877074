ul.stepUl {
  display: flex;
  margin-top: 120px;
  gap: 100px;
}
ul.stepUl li {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

ul.stepUl li .icon {
  font-size: 35px;
  color: #0da5e8;
  margin: 0 60px;
}

ul li .text {
  font-size: 14px;
  font-weight: 600;
  color: #0da5e8;
}

.main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Progress Div Css  */

ul li .progress {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(68, 68, 68, 0.781);
  margin: 14px 0;
  display: grid;
  place-items: center;
  color: #fff;
  position: relative;
  cursor: pointer;
  overflow: visible;
}
.progress::after {
  content: " ";
  position: absolute;
  width: 185px;
  height: 5px;
  background-color: rgba(68, 68, 68, 0.781);
  right: 30px;
}

.one::after {
  width: 0;
  height: 0;
}
ul li .progress .uil {
  display: none;
}
ul li .progress p {
  margin-top: 5px;
  font-size: 13px;
}

/* Active Css  */

ul li .active {
  background-color: #0da5e8;
  display: grid;
  place-items: center;
}
li .active::after {
  background-color: #0da5e8;
}
ul li .active p {
  display: none;
}
ul li .active .uil {
  font-size: 20px;
  display: flex;
}

/* Responsive Css  */

@media (max-width: 980px) {
  ul {
    flex-direction: column;
  }
  ul li {
    flex-direction: row;
  }
  ul li .progress {
    margin: 0 30px;
  }
  .progress::after {
    width: 5px;
    height: 55px;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }
  .one::after {
    height: 0;
  }
  ul li .icon {
    margin: 15px 0;
  }
}
