ul.pagination li {
  padding: 0 !important;
  width: 2em;
  height: 2em;
  /* display: flex; */
  text-align: center;
}
ul.pagination li a {
  width: 100% !important;
  height: 100% !important;
  display: block;
}
