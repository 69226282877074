textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: rgba(126, 239, 104, 0.8);
  /* give your style */
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset,
    0 0 8px rgba(131, 131, 131, 0.6);
  /* give your style */
  outline: 0 none;
  /* give your style */
}

select:focus {
  outline-color: transparent;
}

.accordion-button:focus {
  box-shadow: none !important;
  border: none !important;
}

li.flagContainer {
  white-space: nowrap;
}
/*My Account Order Status Text Class */
.dangerText {
  color: #ff0000 !important;
  width: 10px;
}
.successText {
  color: #008000 !important;
  width: 10px;
}

/* Pagination No */
ul.pagination li a {
  padding-top: 1px;
}
/* presale design */

.pre_order_B2B.en {
  background: url(../public/images/plp/products/presale.png) no-repeat;
  background-size: 100px 100px;
}

.pre_order_B2B {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.noprice {
  background-color: #0da5e8 !important;
  border-right: 0px !important;
}

.nav-link:focus,
.nav-link:hover {
  color: #0da5e8 !important;
}

/* Batman pin */

.modelTitle {
  font-size: 2rem;
  margin: 20px 20px;
  letter-spacing: 0px;
}

.modelPara {
  margin: 10px 20px;
}

/* ---------- Purchase Quantity ---------- */
.pro-qty-cont {
  width: 140px;
  margin: 0px auto;
}
.pro-qty {
  width: 95px;
  margin: 0px auto;
}
.pro-qty .qtybtn {
  display: block;
  float: left;
  line-height: 26px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  background-color: #949494 !important;
  color: #fff !important;
  transition: 0.3s;
  padding: 5px 10px !important;
}
.qtybtn:hover {
  background-color: #0da5e8 !important;
  color: #fff;
}
.pro-qty input {
  width: 40px;
  float: left;
  border: none;
  height: 36px;
  line-height: 30px;
  padding: 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  font-size: 14px;
  font-weight: 500;
  color: #27272e;
}

/* ---------- Icon Navigation ---------- */
ul.icon-navigation {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.icon-navigation li {
  display: block;
  float: left;
  position: relative;
  text-decoration: none;
  /* white-space: nowrap; */
  transition-duration: 0.5s;
  padding: 4px 5px 0px 5px;
}
ul.icon-navigation li a.aboutus {
  font-size: 14px;
  font-weight: 300;
  margin-right: 15px;
}

ul.icon-navigation li a {
  color: #fff;
  padding: 0px;
  font-size: 17px;
}

ul.icon-navigation li a:hover {
  color: #0da6e9;
  cursor: pointer;
  background-color: #929292;
}

ul.icon-navigation li ul.icon-dropdown {
  background-color: #0da5e8 !important;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 0px;
  right: 0;
  display: none;
  padding: 0px;
  z-index: 9999;
}
ul.icon-navigation li ul.icon-dropdown li {
  padding: 0px;
}

ul.icon-navigation li:hover > ul,
ul.icon-navigation li ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
}

ul.icon-navigation li ul li {
  clear: both;
  width: 100%;
}

#catalogs h1 {
  font-size: 24px;
}

#catalogs h2 {
  font-size: 20px;
}

/* ---------- Registration Page ---------- */
.register-main-title {
  color: #0da6e9;
  font-size: 16px;
  text-transform: uppercase;
}
.register-main-title-small {
  color: #0da6e9;
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 20px;
  text-align: center;
}
.reg-text-small {
  font-size: 14px;
  margin: 15px 0px 0px 0px;
}
.register-top-block {
  min-height: 165px;
  margin: 0px;
}
.register-bottom-block {
  margin: 0px;
  overflow: hidden;
}
.topRegister {
  margin-bottom: 6rem;
  margin-top: 5.5rem;
}
.individual3 {
  margin-top: 155px;
}
.shopWholesale {
  margin-top: 113px;
}

.btwob {
  margin: 120px auto 50px auto;
  padding: 0px;
  font-size: 14px;
}
.btwob .form-control {
  border-radius: 0px;
  font-size: 14px;
}
a.terms-and-condition {
  font-size: 14px;
  text-decoration: none;
  color: #000;
  margin-left: 5px;
}
a.terms-and-condition:hover {
  color: #0d6efd;
}

.register-error-message {
  color: red;
  font-size: 14px;
  padding: 5px;
}
.register-success-message {
  color: #00ff00;
  font-size: 14px;
  padding: 5px;
}

.applycode-success-message {
  color: #3a833a;
  font-size: 14px;
  padding: 5px;
}

.register-error {
  margin: 5px 0px 10px 0px;
  padding: 0px;
  color: red;
  font-size: 11px;
}

.checkout-messages {
  margin: 50px 0px 80px 0px;
  padding: 0px;
  text-align: center;
}
.checkout-thankyou {
  margin: 20px 0px;
  padding: 0px;
  font-size: 18px;
  font-weight: bold;
}
.multi-cont .addresses {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  color: #0da5e8;
}

.multi-cont-two .addresses {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  color: #0da5e8;
}

.multi-cont-two ol {
  width: 60%;
  margin: 0px auto 10px auto;
  padding-left: 0px;
  flex-direction: row;
}

.responsiveTable tbody tr {
  border: 1px solid #dedede !important;
}

.shipping-total-two {
  border: 1px solid #dedede;
  padding: 10px;
  margin: 0 0 20px;
  text-align: right;
  overflow: hidden;
}
.shipping-total-two ul {
  float: right;
  padding: 0px;
  margin: 0px;
  width: 100%;
}
.shipping-total-two ul li {
  list-style-type: none;
  padding: 5px 0px;
}
.shipping-total-two .subtotal {
  margin: 0px 0px 0px 5px;
  font-size: 14px;
}
.shipping-total-two .total {
  width: 100%;
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin: 20px 0px 5px 0px;
  padding: 15px 0px 5px 0px;
  border-top: 1px solid #0da5e8;
}
.shipping-total-two .subtotal .price {
  font-weight: 400;
}
.shipping-total-two li .price {
  width: auto;
  padding: 0px 0px 0px 20px;
  float: none;
  display: inline-block;
  color: #0da5e8;
}
.payment-methods {
  color: #333;
  letter-spacing: 9.8px;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 40px;
  font-weight: 600;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  margin: 20px 0px;
}
.payment-paypal,
.payment-redsys {
  margin: 20px 0px;
}
.payment-paypal img,
.payment-redsys img {
  height: 30px;
  padding: 0px 0px 0px 20px;
}
.iagree-cont {
  margin: 10px 0px;
  padding: 0px;
}
.iagree-checkbox {
  float: left;
  margin: 2px 10px 0px 0px;
}
.iagree-cont a {
  text-decoration: none;
}

/* ---------- Mega Menu Drop Down ---------- */
button.navbar-toggler {
  background-color: #fff !important;
  margin-top: 5px;
  font-size: 14px;
}
.navbar-toggler-icon {
  width: 1em;
  height: 1em;
  padding: 10px;
}
.ourbrands-right-border {
  border-right: 1px solid rgb(190, 190, 190);
}

.ourbrands-left-border {
  border-left: 1px solid rgb(190, 190, 190);
}
.menu-ourbrands-cont {
  width: 85%;
  overflow: hidden;
  margin: 0px auto !important;
}
.menu-ourbrands {
  margin: 0px 0px 10px 0px !important;
}
.menu-ourbrands-cont img {
  width: 100%;
}

.menu-ourbrands-cont {
  width: 85%;
  overflow: hidden;
  margin: 0px auto !important;
}

.menu-brand-parent {
  position: relative;
  display: inline-block;
  background-size: cover;
  padding: 0px 0px;
}

.menu-brand-parent img {
  display: block;
  height: 200px;
  padding: 0px 12px;
}

.menu-brand-parent:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  transition: 0.3s;
}

.menu-brand-parent:hover:after {
  background: rgba(0, 0, 0, 0.8);
}

.menu-brand-parent:hover .brand-child {
  opacity: 1;
}

.menu-brand-child {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  color: white;
  opacity: 0;
  transition: 0.5s;
  line-height: 1.5rem;
}
.menu-brand-child:hover {
  opacity: 1;
}

.megamenu-custom-column {
  float: left;
  width: 20% !important;
  margin: 0px 0px 0px 0px !important;
  padding: 0px 0px 0px 0px !important;
}

.megamenu-subheading {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px 0px 10px 10px;
}
.megamenu-subheading-two {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px 0px 10px 0px;
}
.megamenu-column-heading {
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px 0px 0px 15px;
  color: #0da6e9;
}
.megamenu-column-heading-two {
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  padding: 20px 0px 0px 15px;
}

.megamenu .border-right {
  border-right: 1px solid #fff;
  margin: 10px 0px 10px 0px !important;
}
/* ---------- Mega Menu Drop Down ---------- */
ul.products-menu {
  -moz-column-count: 3;
  -moz-column-gap: 20px;
  -webkit-column-count: 3;
  -webkit-column-gap: 20px;
  column-count: 3;
  column-gap: 20px;
  margin: 0px;
  padding: 0px;
}

ul.products-menu li {
  float: left;
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  width: 100%;
  word-wrap: break-word;
  white-space: normal;
  /* border: 1px solid #00ff00; */
}

ul.products-menu li a {
  margin: 0px;
  padding: 0px;
  font-size: 12px;
  text-decoration: none;
  /*padding: 0px 0px 0px 15px !important;*/
  color: #fff;
}
ul.products-menu li a:hover {
  background: transparent !important;
  color: #fff !important;
  text-decoration: underline;
  /*background: url("/public/images/header/submenu-bullet.png") no-repeat left 3px !important;*/
}

.col-megamenu {
  display: grid;
  grid-template-columns: repeat(5, 150px);
}

ul.licenses-menu {
  margin: 0px;
  padding: 0px;
  /* border: 1px solid #ff0000; */
}
ul.licenses-menu li {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}
ul.licenses-menu li a {
  margin: 0px;
  padding: 0px;
  font-size: 12px;
  text-decoration: none;
  padding: 0px 0px 0px 15px !important;
  color: #fff;
  display: inline-block;
}
ul.licenses-menu li a:hover {
  background: transparent !important;
  color: #fff !important;
  background: url("/public/images/header/submenu-bullet.png") no-repeat left 3px !important;
}
.navbar .megamenu {
  padding: 1rem;
}

@media all and (min-width: 992px) {
  .navbar .has-megamenu {
    position: static !important;
  }
  .navbar .megamenu {
    left: 0;
    right: 0;
    width: 100%;
    margin-top: 0;
  }
}

@media (max-width: 991px) {
  .navbar.fixed-top .navbar-collapse,
  .navbar.sticky-top .navbar-collapse {
    overflow-y: auto;
    max-height: 90vh;
    margin-top: 10px;
  }
}

.required-field {
  font-size: 12px;
  color: #e32;
  text-align: right;
}
.billing-cont {
  width: 80%;
  margin: 0px auto;
}

.multi-cont ol {
  width: 80%;
  margin: 0px auto 10px auto;
  padding-left: 0px;
  flex-direction: row;
}
.purchase-container {
  margin: 0px auto;
  padding: 0px;
  width: 80%;
}
.subheading {
  font-size: 14px;
  font-weight: bold;
}
.returning-customer {
  padding: 4rem 2rem;
  border: 1px solid #dcdcdc;
}
.new-customer {
  padding: 4rem 2rem 1.2rem 2rem;
  background: #f4f4f4;
  height: 100%;
}

ol.progtrckr {
  margin: 0;
  padding-bottom: 2.2rem;
  list-style-type: none;
}
ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  padding: 0 0.7rem;
  cursor: pointer;
}
ol.progtrckr li span {
  padding: 0 1.5rem;
}
@media (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }
}
.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}
@media (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }
}

ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
}
ol.progtrckr li.progtrckr-doing {
  color: black;
  border-bottom: 4px solid #33c3f0;
}
ol.progtrckr li.progtrckr-done {
  color: black;
  border-bottom: 4px solid #33c3f0;
}
ol.progtrckr li:after {
  content: "\00a0\00a0";
}
ol.progtrckr li:before {
  position: relative;
  bottom: -3.7rem;
  float: left;
  left: 50%;
}
ol.progtrckr li.progtrckr-todo:before {
  content: "\039F";
  color: silver;
  background-color: white;
  width: 1.2em;
  line-height: 1.4em;
}
ol.progtrckr li.progtrckr-todo:hover:before {
  color: #0fa0ce;
}

ol.progtrckr li.progtrckr-doing:before {
  content: "\2022";
  color: white;
  background-color: #33c3f0;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}
ol.progtrckr li.progtrckr-doing:hover:before {
  color: #0fa0ce;
}

ol.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: white;
  background-color: #33c3f0;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}
ol.progtrckr li.progtrckr-done:hover:before {
  color: #0fa0ce;
}

ul.pagination {
  display: -webkit-box;
  padding-left: 0;
  list-style: none;
  float: right;
}
ul.pagination li.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

ul.pagination li {
  position: relative;
  padding: 0.375rem 0.75rem;
  border-radius: 5px;
  margin: 0px 3px;
  display: block;
  color: #0da5e8;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow;
}
ul.pagination li a {
  text-decoration: none;
}
ul.pagination li:hover {
  z-index: 2;
  color: #0da5e8;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
ul.pagination li.selected {
  z-index: 3;
  background-color: #0da5e8;
  border-color: #0da5e8;
}
ul.pagination li.selected a {
  color: #fff !important;
}
ul.pagination li:first-child {
  margin-left: -1px;
}
ul.pagination li:last-child {
}
/* pagination 

.pagination {
  list-style: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  font-size: 1.2rem;
  gap: 5px;
}

.pagination .page-num {
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 400;
}

.pagination.page-num:hover {
  background-color: #0da6e9;
  color: #fff;
}
*/

.minicart-body {
  height: 272px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}

.mini-cartempty {
  background-color: #fff;
  padding: 10px 0px;
}
.cartempty {
  background-color: #dedede;
  padding: 10px 0px;
}
button.cart-btn span {
  font-size: 12px;
  margin: 0px 0px 0px 5px;
}
.inyour-cart {
  color: #fff;
  margin: 0px 0px 10px 0px;
  padding: 5px;
  font-size: 14px;
  background-color: #0da5e8 !important;
  text-align: center;
}
a.cartcontainer {
  transition: 1s;
}
a.cartcontainer:hover {
  transform: scale(1.3);
}
.cartwrap-three {
  color: #fff;
  padding: 0px;
  width: 125px;
  margin: 0px auto;
}

.cartwrap-center-three {
  margin-left: 47px;
  margin-right: 50px;
  margin-top: 2px;
}
.cartwrap-center-three input[type="text"] {
  border: 0px;
  border-radius: 0px;
  width: 50px;
}

.grayborder {
  border: 1px solid #ced4da;
  padding: 5px 5px 5px 7px;
  margin: 0px 0px 20px 0px;
}
table.checkouttable {
  margin: 0px;
  padding: 0px;
  border: 1px solid #dedede;
}
table.checkouttable tr {
  border-bottom: 1px solid #dedede;
}

table.checkouttable tr th,
table.checkouttable tr td {
  border-right: 1px solid #dedede;
  text-align: center;
  padding: 10px 5px;
}
table.checkouttable tr td {
  word-wrap: break-word;
  white-space: nowrap;
}
.product-name {
  font-size: 14px;
  padding: 5px 0 0;
  text-align: left;
}
.product-name a {
  color: #000;
  text-transform: uppercase;
  max-width: -webkit-calc(100% - 10px);
  max-width: -moz-calc(100% - 10px);
  max-width: calc(250px - 10px);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  height: 20px;
  text-decoration: none;
}
.product-name a:hover {
  color: #0da5e8;
}
.greenhighlight {
  color: #fff !important;
  background-color: #65c24d !important;
}
.redhighlight {
  color: #fff !important;
  background-color: #ff0000 !important;
}
.item-error,
.item-success {
  color: #fff;
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 10px;
  text-transform: uppercase;
  line-height: 1rem;
  /* width: intrinsic; 
  width: -moz-max-content; 
  width: -webkit-max-content; 
  width: max-content; */
}

.item-success {
  background: #65c24d;
}
.item-error {
  background: red;
}
.shipping-total {
  border: 1px solid #dedede;
  padding: 10px;
  margin: 0 0 20px;
  text-align: right;
  overflow: hidden;
  background-color: #dceff7;
  border: 1px solid #0da5e8;
}
.shipping-total ul {
  float: right;
  padding: 0px;
  margin: 0px;
  width: 100%;
}
.shipping-total ul li {
  list-style-type: none;
  padding: 5px 0px;
}
.shipping-total .subtotal {
  margin: 0px 0px 0px 5px;
  font-size: 14px;
}
.shipping-total .total {
  width: 100%;
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin: 20px 0px 5px 0px;
  padding: 15px 0px 5px 0px;
  border-top: 1px solid #0da5e8;
}

shipping-total .subtotal .price {
  font-weight: 400;
}
.shipping-total li .price {
  width: auto;
  padding: 0px 0px 0px 20px;
  float: none;
  display: inline-block;
  color: #0da5e8;
}

.pro-count {
  /* position: absolute; */
  right: -11px;
  top: -5px;
  color: #000;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  background-color: #0da5e8;
}
ul.product-list {
  list-style: none outside;
  padding: 0;
  margin: 0px;
  max-height: 270px;
  overflow-y: auto;
  background-color: #fff;
}
ul.product-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 5px 0px;
  border-bottom: 1px solid #0da5e8;
}

ul.product-list li .thumb {
  margin: 0px;
}
ul.product-list li .thumb a img {
  width: 80px;
}
ul.product-list li .content {
  padding: 0px;
}
ul.product-list li .content .inner {
  margin-bottom: 0;
  line-height: 20px;
  float: left;
  width: 150px;
}
ul.product-list li .price-delete {
  float: left;
  width: 70px;
  text-align: right;
  padding-right: 5px;
}
ul.product-list li .price-delete .price {
  color: #0da5e8;
  clear: both;
}

ul.product-list li .content .inner a {
  color: #000;
  margin: 0px;
  padding: 0px;
  font-size: 12px;
  line-height: 1rem;
  word-break: break-all;
}
ul.product-list li .content .inner .quatity {
  display: block;
}
ul.product-list li .content .delete-btn {
  right: 0;
  top: 4px;
  position: relative;
  border: 0 none;
}

/* Cart Items */
.minicart-header a,
.minicart-header a:hover {
  color: #000 !important;
  font-size: 14px !important;
}

ul.cart-item {
  margin: 0px;
  padding: 0px;
  background-color: #fff;
  overflow-y: auto;
  height: 200px;
}
ul.cart-item li {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}
ul.cart-item li img {
  width: 90px;
  height: 90px;
}

/* Cart Items */
.minicart-cont {
  padding: 10px;
  font-size: 14px;
  width: 345px;
}
.minicart-header {
  padding: 5px 0px;
}

.minicart-footer {
  margin: 0px 0px;
  padding: 10px 0px 5px 0px;
  font-weight: bold;
}
.scroll-one {
  margin: 0px 0px 30px 0px;
  padding: 4px;
  width: auto;
  height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}
.table-invoice {
  overflow-x: unset !important;
}
.scroll-two {
  margin: 0px 0px 30px 0px;
  padding: 4px;
  width: auto;
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}

.user-sign-two {
  text-transform: uppercase;
  overflow: hidden;
  padding: 10px 0px;
  /* 
  padding: 0px !important;
  border-radius: 1px !important;
  background-color: #0da5e8 !important; */
}
ul.myaccount {
  margin: 0px;
  padding: 0px;
  width: 200px;
  overflow: hidden;
}
ul.myaccount li {
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 5px 20px !important;
  list-style-type: none;
}
ul.myaccount li:hover {
  color: #fff;
  background-color: #006d9c;
}

ul.myaccount li button {
  font-size: 12px;
  font-weight: normal;
}

ul.myaccount li a {
  margin: 0px;
  font-size: 12px;
  height: auto;
}
ul.myaccount li a:hover {
  color: #0a8fc9;
  background-color: #006d9c;
}

.progressbar-wrapper {
  margin: 50px 0px;
}
.progressbar li {
  list-style-type: none;
  width: 30%;
  float: left;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}
.progressbar li:before {
  width: 30px;
  height: 30px;
  content: "";
  line-height: 30px;
  border: 2px solid #7d7d7d;
  display: block;
  text-align: center;
  margin: 0 auto 3px auto;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  background-color: #fff;
}
.progressbar li:after {
  width: 100%;
  height: 10px;
  content: "";
  position: absolute;
  background-color: #7d7d7d;
  top: 11px;
  left: -50%;
  z-index: 0;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active {
  color: #0da5e8;
}
.progressbar li.active:before {
  border-color: #0da5e8;
  background: #0da5e8;
}
.progressbar li.active + li:after {
  background-color: #0da5e8;
}
.progressbar li.active:before {
  background-size: 60%;
}
.progressbar li::before {
  background-size: 60%;
}

.extramargin {
  width: 80%;
  margin: 20px auto !important;
}
.thumbnail {
  width: 48px;
  height: 48px;
}
.checkoutImage {
  width: 100px !important;
  height: 100px !important;
}
.mode {
  text-align: right;
}
a.bi-icon {
  margin: 0px 0px 0px 3px;
  color: #0c85bb;
}
.launch-heading {
  font-size: 16px;
  margin: 20px 0px 10px 0px;
  color: #0da5e8;
}
.required-field {
  font-size: 11px;
  color: red;
  text-align: right;
  padding-top: 5px;
}
.rf {
  color: red;
}
.backbutton a {
  text-decoration: none;
  color: 000;
}
.fieldset {
  border: 1px solid #dedede;
  margin: 30px 0 0;
  padding: 15px;
}
.fieldset .legend.large {
  font-size: 20px;
}
.fieldset .legend {
  color: #fff;
  float: left;
  line-height: normal;
  margin: -30px 0 0;
  outline: 0;
  padding: 3px 12px 5px 12px;
  position: relative;
  text-transform: uppercase;
  background: #0da5e8;
  border-radius: 4px;
}
.search-cont {
  margin: 10px 0px;
}
.search {
  width: 100%;
  margin-bottom: auto;
  height: 47px;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.search a {
  text-decoration: none;
}

.search .search-input {
  padding: 0px;
  width: 100%;
  caret-color: #536bf6;
  color: black;
  transition: width 0.4s linear;
  line-height: 20px;
  border: 0;
  outline: 0;
  background: none;
}

.search-icon {
  height: 34px;
  width: 34px;
  margin-top: 5px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #536bf6;
  font-size: 10px;
  bottom: 30px;
  position: relative;
  border-radius: 5px;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-search:before {
  content: "\f002";
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.myaccount-page-body .pdf-icon-cont {
  display: flex;
  flex-direction: column;
  justify-content: right;
  text-align: right;
}
.myaccount-page-body .pdf-icon {
  width: 28px;
  height: 28px;
  margin-left: 10px;
}

.myaccount-page-body {
  font-size: 13px;
  line-height: 1.3rem;
  margin: 20px 0px 20px 0px;
}
.myaccount-page-body .page-header {
  font-size: 14px;
  padding-top: 30px;
  padding-bottom: 10px;
}
.myaccount-page-body .page-header h3 {
  color: #0da5e8;
  font-size: 30px;
  margin-bottom: 10;
  font-weight: 800;
  text-transform: uppercase;
}
.myaccount-tab-cont {
  margin: 30px 0px;
}
.myaccount-tab-cont .tab-content {
  padding: 30px 0px 10px 0px;
}
.myaccount-tab-cont .nav-tabs .nav-link {
  font-size: 16px;
  margin-right: 2px;
  background-color: #d7eaf1;
}
.myaccount-tab-cont .nav-tabs .nav-link.active {
  font-size: 16px;
  margin-right: 2px;
  background-color: #fff;
}
.myaccount-page-body .form-control {
  font-size: 12px;
  border-radius: 0px;
  font-weight: normal;
}
.form-select {
  font-size: 12px !important;
  border-radius: 0px !important;
  font-weight: normal !important;
  color: #212529 !important;
}
.myaccount-page-body .highlight {
  color: #0da5e8;
  font-weight: bold;
}
.myaccount-page-body .basicinfo {
  width: 85%;
  margin: 20px auto 0px auto;
}
.myaccount-page-body .basicinfo .rowheight {
  padding: 5px 0px 10px 0px;
  border-bottom: 1px solid rgb(224, 224, 224);
}
.checkoutbutton {
  background-color: #000;
  color: #fff;
  margin: 0px 1px 5px 1px;
  padding: 10px 40px;
  border: none;
  text-transform: uppercase;
}
.checkoutbutton:hover {
  background-color: #0da5e8;
}
.proceed-btn {
  background-color: #0da5e8 !important;
}

.proceed-btn:hover {
  background-color: #000 !important;
}

/* ---------- General Block ---------- */
/* ::-webkit-scrollbar {
  display: none;
} */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* -webkit-appearance: none; */
  margin: 0;
}

/* Firefox 
input[type="number"] {
  -moz-appearance: textfield;
}
*/

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 2560px !important;
  }
}

p,
input,
span {
  font-family: "Lexend" !important;
}

label,
option {
  font-family: "Lexend" !important;
}

select {
  font-family: "Lexend" !important;
}

.anchor {
  font-family: "Lexend" !important;
}

.sliders {
  height: 250px;
}

/* ---------- Common Elements Block ---------- */
.banner-small-cont {
  margin: 20px 0px 10px 0px;
}
.sortby {
  margin: 0px 0px 10px 0px;
  border-bottom: 1px solid #d2d4d5;
  padding-bottom: 10px;
}
.page-body-container {
  font-size: 13px;
  line-height: 1.3rem;
  margin: 40px 0px;
  margin-top: 6rem;
  min-height: 750px;
}

.section-heading {
  color: #0da5e8;
  font-size: 36px;
  font-weight: 700;
  line-height: 2.5rem;
  margin: 0 auto;
  text-align: center;
  width: 90%;
}

.section-paragraph {
  color: #000;
  font-size: 18px;
  margin: 10px auto;
  text-align: center;
  width: 85%;
}
.section-paragraph p {
  margin: 0px;
}

.section-heading-left {
  color: #0da5e8;
  font-size: 36px;
  font-weight: 700;
  line-height: 2.5rem;
  text-align: left;
}

.section-paragraph-left {
  color: #000;
  font-size: 18px;
  text-align: left;
  width: 85%;
}
.reset-password-cont {
  font-size: 11px;
  border-top: 1px solid #aad4e7;
  padding: 20px 0px;
  width: 315px;
  overflow: hidden;
}
.errorm {
  font-size: 10px;
  color: red;
  letter-spacing: 1px;
  margin-bottom: 5px !important;
  text-transform: initial;
}

/* ---------- Header Block ---------- */
/* ---------- Header Block ---------- */
/* ---------- Header Block ---------- */
/* ---------- Header Block ---------- */
/* ---------- Header Block ---------- */
/* ---------- Header Block ---------- */

/* ---------- Disney Page Block ---------- */
.our-customers-cont {
  padding: 20px 0px 20px 0px;
  background: #dbdbdb url("/public/images/home/product-categories/lp-top.png")
    repeat-x;
}
.our-customers-cont-two {
  padding: 20px 0px 20px 0px;
  background-color: #dbdbdb;
}
.our-customers-bottom {
  height: 10px;
  background: #dbdbdb url("/public/images/disney/our-customers-bottom.jpg")
    repeat-x;
}

/* ---------- Product Listing Page (PLP) Block ---------- */
.swapimg-container-two {
  position: relative;
}

.productimage {
  position: relative;
  overflow: hidden;
  /* width: 75%;
  max-width: 200px;
  min-width: 200px; */
  /* max-width: 170px;
  min-width: 170px; */
  margin: 0px auto;
}
.productimage a {
  display: table;
  margin: 0 auto;
}

.product-default a {
  text-decoration: none;
}
.productimage img {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin-bottom: 10px;
  margin: 0 auto;
  width: 100%;
  /* height: 100%; */
}
.productimage:hover img {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}
.productimage:hover {
  display: block;
}
.cardnoborder {
  border: 0px !important;
  margin-bottom: 5px;
}
.cartmt {
  margin-top: -20px;
}
.cartwidth {
  width: 227px !important;
  overflow: hidden;
  margin-bottom: 50px;
}

.filter-accordion-cont {
  text-transform: uppercase;
  margin-bottom: 20px;
}
.filter-accordion-cont span.disabledlabel {
  color: #000 !important;
}
.filter-accordion-cont .MuiTypography-root {
  font-size: 14px;
  line-height: 1.5rem;
}
.filter-accordion-cont .MuiPaper-root {
  border: 1px solid #ccc;
}
.filter-accordion-cont .MuiAccordionDetails-root {
  padding: 0px 16px 16px;
}
.colorpalette-cont {
  margin: 0px;
  overflow: hidden;
}

/* ul.colorpalette-colors {
  margin: 0px;
  padding: 0px;
}
ul.colorpalette-colors li {
  float: left;
  margin: 0px 10px 5px 0px;
  padding: 0px;
  list-style-type: none;
}
ul.colorpalette-colors li a {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}
ul.colorpalette-colors li a img {
  width: 24px;
  height: 24px;
} */
/* Color Pallette */
ul.colorpalette-colors {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

ul.colorpalette-colors li {
  display: inline-block;
  margin: 0px;
  padding: 0px 5px 5px 0px;
}

ul.colorpalette-colors li input[type="checkbox"][id^="cb"] {
  display: none;
}

ul.colorpalette-colors li label {
  display: block;
  position: relative;
  cursor: pointer;
}

ul.colorpalette-colors li label:before {
  color: white;
  background-color: #00ff00;
  content: " ";
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 3px;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 20px;
  transition-duration: 0.4s;
  transform: scale(0);
  z-index: 999;
  font-weight: bold;
}

ul.colorpalette-colors li label img {
  height: 24px;
  width: 24px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

ul.colorpalette-colors li :checked + label:before {
  content: "✓";
  transform: scale(1);
}

ul.colorpalette-colors li :checked + label img {
  z-index: -1;
}
.border-top {
  margin: 20px;
}

.sbHolder {
  position: relative;
  background: #fff;
  border: 1px solid #9b9b9b;
  float: left;
  width: 235px;
  padding: 1px 2px;
  border-color: #cdcdcd;
}
.sbHolder a {
  display: block;
  color: #191919;
  line-height: normal;
}
.sbHolder .sbToggle {
  position: absolute;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  height: 20px;
  right: 2px;
  top: 1px;
  width: 20px;
  /* background:url(../images/down-arrow.png) no-repeat 50% 50%; */
  background-color: transparent;
}
.sbOptions {
  background-color: #fff;
  overflow-y: auto;
  padding: 3px 0;
  position: absolute;
  z-index: 2;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #9b9b9b;
  right: -1px;
  left: -1px;
  top: 22px !important;
  font-size: 95%;
}
.sbOptions li {
  display: block;
  line-height: normal;
  margin: 0;
  padding: 0 3px;
}
.sbOptions li a {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: none 0;
  display: block;
  float: none;
  line-height: normal;
  margin: 0;
  padding: 3px 4px;
}
.sbOptions li a:hover {
  color: #fff;
  background: #fb5a13;
}
.sort-by {
  width: 215px;
  margin-left: 3px;
}
.sort-by:after,
.sort-by:before {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
}
.sort-by:after {
  clear: both;
}

.view-mode:after,
.view-mode:before {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
}
.view-mode:after {
  clear: both;
}
.view-mode > a span {
  cursor: pointer;
  -webkit-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  border-radius: 3px;
  float: left;
  width: 24px;
  height: 24px;
  font-size: 14px;
  color: #666;
  margin: 0 0 0 3px;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  background: #dddddd;
}
.view-mode > a span:hover {
  color: #fff;
  background: #0da6e9;
}
.view-mode > span span {
  top: 0;
  right: -1px;
  display: block;
}
.view-mode > a span.active {
  color: #fff;
  background: #0da6e9;
}
.view-mode > span.active {
  color: #fff;
  background: #0da6e9;
}

.page-heading {
  font-size: 16px;
  color: #0da6e9;
  text-transform: uppercase;
  margin-top: 20px !important;
}
.product-default {
  margin: 20px 0px 0px 0px;
}
.product-default figure {
  margin: 0px 0px 5px 0px;
}

.product-details a {
  text-decoration: none;
  font-size: 12px;
  color: #495057;
  margin-right: 10px;
}
.product-main-title {
  line-height: 1;
  color: #0da6e9;
  font-size: 24px;
  text-transform: uppercase;
}
.product-title-small {
  font-size: 16px;
  margin-bottom: 10px;
}
.product-details .product-title {
  line-height: 1;
  text-align: center;
  color: #0da6e9;
  font-size: 24px;
  margin: 0px auto;
  width: 224px;
}
.product-details .product-title a {
  padding: 3px 0px 3px 0px;
  width: 195px;
}
.product-details .product-title a:hover {
  color: #0da6e9;
}

.product-details .category-list {
  text-align: center;
  font-size: 18px;
  color: #0da6e9;
  margin: 5px 0px 0px 0px;
}
.product-details .category-list-two {
  font-size: 12px;
  color: #0da6e9;
  margin: 1px 0px 0px 0px;
}

.price-box {
  margin: 0px 0px 10px 0px;
  font-weight: bold;
  overflow: hidden;
}
.price-box .custom-code {
  padding: 0px 0px 0px 12px;
}
.price-box .custom-code a:hover {
  color: #0da6e9;
}

.price-box .old-price {
  text-decoration: line-through;
  letter-spacing: 0.005em;
  color: #999;
  margin-right: 3px;
}
.price-box .product-price {
  color: #222529;
}
.hprice-box {
  width: 100%;
  margin: 0 0 10px;
  position: relative;
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
  padding: 0 10px 0 0;
  display: inline-block;
}
.hprice-box:before {
  content: "";
  width: 57px;
  height: 1px;
  position: absolute;
  background: #fb5a13;
  left: 0;
}
.hprice-box:after {
  content: "";
  width: 57px;
  height: 1px;
  position: absolute;
  background: #fb5a13;
  left: 0;
}
.hprice-box .special-price {
  padding: 18px 0px;
  margin: 0;
  display: inline-block;
}
.hprice-box .special-price .price {
  float: left;
  font-size: 34px;
  color: #434343;
  display: block;
  font-weight: 600;
}
.hprice-box .special-price .price-strike {
  float: left;
  text-decoration: line-through;
  font-size: 34px;
  color: #434343;
  display: block;
  font-weight: 600;
  margin-right: 20px;
}

/* ---------- Marvel Page  Block ---------- */
.lptcont-three {
  bottom: 10px;
  left: 20px;
  position: absolute !important;
}

.lptcont-three h2 {
  font-size: 3.125rem;
  font-weight: bold;
  margin-bottom: 0px;
}
.lptcont-three p.subheading {
  font-size: 2.125rem;
  margin-bottom: 0px;
}

.lptcont-four {
  width: 600px;
  bottom: 10px;
  padding: 10px;
  left: 20px;
  position: absolute !important;
  background: url(/public/images/home/products/lg-transparent.png) repeat;
}

.lptcont-four .section-heading {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 0px;
  text-align: left;
  width: 100%;
}
.lptcont-four p.section-paragraph {
  font-size: 18px;
  margin-bottom: 0px;
  width: 100%;
  text-align: left;
}
.registermarvel-cont {
  width: 95%;
  margin: 0px auto;
}
.conditionMarvel {
  margin: 20px 0px 20px 0px;
}
.conditionHeading {
  font-size: 30px;
  font-weight: bold;
  color: #0da5e8;
  text-align: center;
  line-height: 2rem;
  width: 100%;
  margin: 30px 0px 10px auto;
}

/* ---------- ProDG Page Block ---------- */
.banner-vh-center-text-bg {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative !important;
}
.banner-vh-center-text-bg img {
  width: 100%;
  height: 100%;
}
.cheight {
  width: 100%;
  height: 485px !important;
}

.banner-vh-center-text-bg .center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.banner-vh-center-text-bg .center .hs-cont {
  line-height: 1rem;
  color: #fff;
}
.banner-vh-center-text-bg .center h2 {
  font-size: 60px;
  font-weight: bold;
  margin: 0px;
  padding: 0px;
}
.banner-vh-center-text-bg .center p {
  font-size: 30px;
  margin: 0px;
  padding: 0px;
}
.banner-vh-center-text-bg .center .anchorbg {
  width: 100%;
  padding: 5px;
  margin-top: 20px;
  background-color: #fff;
  background: url(/public/images/home/products/lg-transparent.png) repeat;
}
.banner-vh-center-text-bg .center .anchorbg a {
  font-size: 30px;
  text-decoration: none;
  color: #fff;
  padding: 10px;
  line-height: 2rem;
}
.banner-vh-center-text-bg .center .anchorbg a:hover {
  color: #0da5e8;
}

/* ---------- OMP Page Block ---------- */

.banner-v-center-text-cont {
  margin: 20px 20px;
}
.banner-v-center-text-bg {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative !important;
  border: 1px solid #ccc;
}
.banner-v-center-text-bg img {
  width: 100%;
  height: 100%;
}

.banner-v-center-text-bg .left {
  position: absolute;
  width: 40%;
  left: 25%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.banner-v-center-text-bg .left .hs-conttwo {
  color: #fff;
  line-height: 1rem;
}
.banner-v-center-text-bg .left h2 {
  font-size: 36px;
  font-weight: bold;
  margin: 0px;
  padding: 0px;
}
.banner-v-center-text-bg .left p {
  font-size: 18px;
  margin: 0px;
  padding: 0px;
  line-height: 1.5rem;
}

.banner-v-center-text-bg .left h2 {
  font-size: 36px;
  font-weight: bold;
  margin: 0px;
  padding: 0px;
}
.banner-v-center-text-bg .left p {
  font-size: 18px;
  margin: 0px;
  padding: 0px;
  line-height: 1.5rem;
}
.banner-v-center-text-bg .left button {
  font-size: 18px;
  margin: 20px 0px;
  padding: 10px 40px;
  border: none;
}
.banner-v-center-text-bg .left button {
  background-color: #000;
  color: #fff;
}
.banner-v-center-text-bg .left button:hover {
  background-color: #0da5e8;
}

.banner-v-center-text-bg .left .anchorbg {
  width: 100%;
  padding: 5px;
  margin-top: 20px;
  background-color: #fff;
  background: url(/public/images/home/products/lg-transparent.png) repeat;
}
.banner-v-center-text-bg .left .anchorbg a {
  font-size: 30px;
  text-decoration: none;
  color: #fff;
  padding: 5px;
  line-height: 2rem;
}
.banner-v-center-text-bg .left .anchorbg a:hover {
  color: #0da5e8;
}

/* --- High School --- */
.banner-v-center-hstext-bg {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative !important;
  border: 1px solid #ccc;
}
.banner-v-center-hstext-bg img {
  width: 100%;
  height: 100%;
}
.banner-v-center-hstext-bg .left {
  position: absolute;
  left: 25%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.banner-v-center-hstext-bg .left .hs-conttwo {
  color: #fff;
  line-height: 1rem;
}
.banner-v-center-hstext-bg .left h2 {
  font-size: 60px;
  font-weight: bold;
  margin: 0px;
  padding: 0px;
}
.banner-v-center-hstext-bg .left p {
  font-size: 60px;
  margin: 0px;
  padding: 0px;
  line-height: 1.5rem;
}
.banner-v-center-hstext-bg .left button {
  font-size: 18px;
  margin: 20px 0px;
  padding: 10px 40px;
  border: none;
}
.banner-v-center-hstext-bg .left button {
  background-color: #000;
  color: #fff;
}
.banner-v-center-hstext-bg .left button:hover {
  background-color: #0da5e8;
}
.banner-v-center-hstext-bg .left .anchorbg {
  width: 100%;
  padding: 5px;
  margin-top: 20px;
  background-color: #fff;
  background: url(/public/images/home/products/lg-transparent.png) repeat;
}
.banner-v-center-hstext-bg .left .anchorbg a {
  font-size: 30px;
  text-decoration: none;
  color: #fff;
  padding: 5px;
  line-height: 2rem;
}
.banner-v-center-hstext-bg .left .anchorbg a:hover {
  color: #0da5e8;
}

/* ProDGSpecialConditions */
.ProDGSpecial-container {
  margin: 40px 100px;
}
.ProDGSpecial-content {
  margin: 0px 50px 0px 0px;
}
.ProDGSpecial-content .heading {
  font-size: 26px;
  font-weight: bold;
  line-height: 2.5rem;
  color: #0da5e8;
}
.ProDGSpecial-content .body {
  font-size: 18px;
  margin-top: 10px;
}
.blackoutline {
  display: inline-block;
  color: rgb(85, 85, 85);
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 1px solid rgb(187, 187, 187);
  cursor: pointer;
  box-sizing: border-box;
  margin: 20px 10px 20px 0px;
  padding: 8px 60px;
}
.blackoutline:hover {
  background-color: #0da5e8;
  color: #fff;
}

.blackbutton-continue {
  font-size: 14px;
  margin: 20px 0px;
  padding: 10px 0px;
  border: none;
  background-color: #000;
  color: #fff;
  text-transform: uppercase;
  width: 100%;
}
.blackbutton-continue:hover {
  background-color: #0da5e8;
}

.blackbutton {
  font-size: 14px;
  margin: 20px 0px;
  padding: 10px 60px;
  border: none;
  background-color: #000;
  color: #fff;
  text-transform: uppercase;
}

.loginbutton {
  font-size: 14px;
  padding: 24px 10px;
  border: none;
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
  color: #000;
  text-transform: uppercase;
  width: 100%;
}

.loginbutton:hover {
  background-color: #0da5e8;
  color: #fcfcfc;
}

.blackbutton:hover {
  background-color: #0da5e8;
  color: #fff;
}
.checkout:hover {
  background-color: #fff !important;
  color: #000 !important;
}

.ProDGSpecial-bottom {
  width: 100%;
  height: 9px;
  background: url("/public/images/home/product-categories/lp-top.png") repeat-x;
}

/* ---------- OMP Category Page Block ---------- */
.main-banner-cont {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
}
.main-banner-cont a {
  text-decoration: none;
  line-height: 3rem;
  color: #fff;
}
.main-banner-heading {
  font-size: 60px;
  font-weight: bold;
}
.main-banner-subheading {
  font-size: 50px;
}
.lptcont-two {
  bottom: 10px;
  position: absolute !important;
}
.lptcont-two h2 {
  bottom: 10px;
  font-size: 50px;
  font-weight: bold;
  color: #000;
}
.lptbg-two {
  font-size: 20px;
  width: 100%;
  padding: 5px;
  margin-bottom: 0px;
  background-color: #fff;
  background: url(/public/images/home/products/lg-transparent.png) repeat;
}
.lptbg-two a {
  text-decoration: none;
  color: #000;
  font-weight: bold;
}
.lptbg-two a:hover {
  color: #0da6ea;
}
/* Product Supplier */
.product-supplier-cont {
  background-color: #ebebeb;
}
.product-supplier-cont-top {
  width: 100%;
  height: 7px;
  background: url("/public/images/prodg/supplier-top.png") repeat-x;
}
.product-supplier-cont-bg {
  padding: 20px;
}
.product-supplier-cont .heading {
  color: #0da5e8;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  text-transform: uppercase;
}
ul.ps-list {
  margin: 20px 0px 20px 0px;
  padding: 0px;
  font-size: 16px;
}
ul.ps-list li {
  list-style-type: none;
}
ul.ps-list li a {
  text-decoration: none;
  color: #000;
}
ul.ps-list li a:hover {
  color: #0da5e8;
}

/* ----- Product Details Page ------ */

.product-detail-cont {
  background-color: #ebebeb;
  padding: 20px 20px 40px 20px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 80px;
}
.product-detail-cont .heading-cont {
  margin: 0px 0px 0px 0px;
}
.product-detail-cont .heading-cont .heading {
  font-size: 22px;
  font-weight: bold;
  color: #0da5e8;
}
.product-detail-cont .heading-cont .description {
  font-size: 30px;
  font-weight: bold;
}
.product-tab-cont {
  font-size: 18px;
  color: #747575;
  margin: 10px 0px 30px 0px;
  background: url("/public/images/detailed/tab-bg.jpg") no-repeat;
}

/* Navigation Tabs */
.slantedtabs .nav-tabs {
  margin: 10px 10px 0px 11px;
  border-bottom: 0px !important;
}
.slantedtabs .nav-tabs .nav-item.show .nav-link,
.slantedtabs .nav-tabs .nav-link.active {
  color: #000;
  background-color: #fff;
}
.slantedtabs .nav-tabs .nav-link:focus,
.slantedtabs .nav-tabs .nav-link:hover {
  color: #000;
  background-color: #fff;
}
.slantedtabs .nav-tabs .nav-link {
  color: #000;
  border: 0px !important;
  background-color: #ccc;
  margin: 0px 0px 0px 0px;
  padding: 0px 15px 0px 10px;
  transform: skewX(40deg);
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.slantedtabs .nav-tabs .nav-link span.slanted-text {
  transform: skewX(320deg);
  display: inline-block;
}
.nav-tabs .nav-link:nth-child(1) {
  color: #fff;
  background-color: #aaaaaa;
}
.nav-tabs .nav-link:nth-child(2) {
  color: #fff;
  background-color: #c5c5c5;
}
.nav-tabs .nav-link:nth-child(3) {
  color: #fff;
  background-color: #8b8b8b;
}

.tab-content {
  padding: 10px 10px 60px 10px;
  background-color: #fff;
}
.details-cont {
  margin: 0px;
}
.details-cont .heading {
  margin: 0px;
  font-weight: bold;
  height: 40px;
  padding: 6px 0px 0px 0px;
  background: url("/public/images/detailed/ds-heading-bg.jpg") no-repeat;
}
.details-cont .description {
  margin: 0px 0px 10px 0px;
}
.details-cont ul.data-points {
  margin: 0px;
  padding: 0px;
}
.details-cont ul.data-points li {
  margin: 0px;
  padding: 2px 0px 2px 0px;
  list-style-type: none;
  border-bottom: 1px solid #cdcdcd;
}
.details-cont ul.data-points li:last-child {
  border-bottom: none;
}

/* Heading Underline Above and Below */
.heading-ab-underlines {
  font-weight: bold;
  font-size: 30px;
  border-top: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  padding: 5px 0px;
  margin: 25px auto;
  text-align: center;
  width: 80%;
}

/* Price and Shipping Details */
.pd-price-cont {
  border: 2px solid #000;
  background-color: #000;
  overflow: hidden;
  height: 70px;
}
.pd-price-cont-price {
  color: #fff;
  float: left;
  width: 40%;
  height: 66px;
  font-size: 20px;
  text-align: center;
  padding: 18px 0px 0px 0px;
}
.pd-price-cont-amount {
  color: #000;
  float: left;
  width: 60%;
  height: 66px;
  background-color: #ebebeb;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding: 16px 0px 0px 0px;
}

.pd-vat {
  font-size: 10px;
  text-align: right;
}
.pd-divider-one {
  margin: 0px 0px;
  border: 1px solid #cdcdcd;
}
.pd-divider-two {
  margin: 10px 0px;
  border: 1px solid #cdcdcd;
}
.pd-usertype-cont {
  margin: 0px 10px 0px 10px;
  clear: both;
}
.pd-usertype {
  background-color: #7b7b7b;
  padding: 10px;
  color: #fff;
  margin-bottom: 10px;
}
.pd-usertype:hover {
  background-color: #585858;

  color: #fff;
}
.pd-usertype a {
  font-size: 28px;
  color: #fff;
  text-decoration: none;
  line-height: 1.5rem;
}
.pd-usertype a:hover {
  color: #fff;
}

.pd-brand {
  padding: 0px;
  border-top: 1px solid #cdcdcd;
  border-bottom: 1px solid #cdcdcd;
}
.pd-sk-cont {
  background-color: #0d6efd;
  padding: 1px;
}
.pd-sk-width {
  width: 100%;
}
.pd-sk-atk {
  font-size: 15px;
  padding: 2px 0px 0px 10px;
}
ul.pduser li {
  background-color: #fff;
  font-size: 16pt;
  font-weight: bold;
  list-style-type: none;
  margin: 0px 0px 13px 0px;
  padding: 10px 50px 10px 10px;
  width: 100%;
  line-height: 2rem;
}

ul.pduser li a {
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
  font-size: 20px;
}

ul.pduser li a:hover {
  color: #0da6e9;
}
ul.pduser li a img {
  float: right;
}

/* Cartwrap two*/
.cartwrap-two {
  width: 100%;
  background-color: #000;
  /* padding: 1px 0px 2px 0px; */
  margin: 10px 0px 5px 0px;
  overflow: hidden;
}
.cartwrap-two-left,
.cartwrap-two-right {
  width: 50px;
  height: 100%;
}
.cartwrap-two-left-two {
  float: left;
  text-align: center;
}
.cartwrap-two-center {
  margin-left: 47px;
  margin-right: 50px;
  margin-top: 2px;
}
.cartwrap-two-right {
  float: right;
}

.paacenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

/* Casual Gaming */
.casual-gaming-cont {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 20px 0px;
}
.casual-gaming-cont img {
  width: 100%;
  height: 100%;
}


/* Major Brands */
.major-brands-cont {
  margin: 60px auto;
  overflow: hidden;
  text-align: center;
}
.major-brands-cont ul.brands {
  margin: 0px;
  display: table-row-group;
}
.major-brands-cont ul.brands {
  position: relative;
}
.major-brands-cont ul.brands li {
  width: 192px;
  height: auto;
  list-style-type: none;
  float: left;
  text-align: center;
  margin: 0px 20px 20px 0px;
}
.major-brands-cont ul.brands li a {
  font-size: 25px;
  font-weight: bold;
  color: #757575;
  text-transform: uppercase;
  text-decoration: none;
  margin: 10px 0px 0px 0px;
}
.major-brands-cont ul.brands li a:hover {
  color: #0da5e8;
}
.major-brands-cont ul.brands li img {
  width: auto;
  height: auto;
}
.major-brands-cont ul.brands li .top-img {
  position: absolute;
  left: 26px;
  top: 0px;
  opacity: 0;
  transition: all 0.7s ease;
}
.major-brands-cont ul.brands li .top-img:hover {
  opacity: 1;
}
/* ----- Breadcrumb ----- */
.breadcrumb-cont {
  padding: 0px 0px 20px 0px;
}

/* ---------- Header Block ---------- */
.nav-container {
  width: 100%;
  background-color: #000;
  overflow: hidden;
}
.headerLogo {
  width: 13.5rem;
}
.navbar {
  margin: 0px !important;
  padding: 0px !important;
}
.navbar-nav {
  margin: 10px 0px 10px 0px !important;
  padding: 0px 0px 0px 0px !important;
}
.navbar-nav nav-item {
  margin: 0px;
}

.navbar-nav nav-item {
  margin: 0px;
}
a.nav-link {
  display: block;
  color: #fff;
  text-transform: uppercase;
  padding: 5px 10px 15px 10px !important;
}
a.nav-link:hover {
  color: #0da6e9;
  background: url("/public/images/header/main-menu-bg.png") bottom left repeat-x !important;
}

a.nav-link:active {
  color: #0da6e9;
  background: url("/public/images/header/main-menu-bg.png") bottom left repeat-x !important;
}
.navbar-nav li:hover > ul.dropdown-menu {
  display: block;
}
.dropdown-toggle::after {
  display: none !important;
}
ul.navbar-nav li.nav-item ul.dropdown-menu {
  border: 0px !important;
  border-radius: 0px !important;
  border-top: 10px solid #0da5e8 !important;
  margin: 0px !important;
  padding: 0px 0px 0px 5px !important;
  color: #fff !important;
  background: url("/public/images/header/dropdown-menu-bg.png") repeat !important;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}
.dropdown-item-two {
  padding: 5px 20px;
}

/* ul.navbar-nav li.nav-item ul.dropdown-menu a {
  padding: 0px 0px 0px 20px !important;
  color: #fff;
} 

ul.navbar-nav li.nav-item ul.dropdown-menu a:hover {
  background: transparent !important;
  color: #fff !important;
  background: url("/public/images/header/submenu-bullet.png") no-repeat !important;
}

*/

.custom-search {
  margin: 0px;
}
.custom-search .form-control {
  padding: 0px 5px;
}
.custom-search .cs {
  font-size: 13px;
  margin-right: 10px !important;
  padding: 5px 10px !important;
  border-radius: 0px;
  outline: none;
}
.custom-search .sb {
  font-size: 12px;
  border-radius: 0px;
  margin: 0px !important;
  padding: 5px 15px !important;
}
.karactermania-wrap {
  background: #929292;
}
.karactermania-wrap .desc {
  color: #fff;
  font-size: 11px;
  padding: 7px 0px;
}
.right-navigation-links {
  display: inline-block;
}
.right-navigation-links a.language {
  font-size: 13px;
  padding: 0px 0px 0px 15px;
}
.right-navigation-links a {
  width: auto;
  height: 28px;
  color: #fff;
  padding: 0px 5px;
  margin-right: 1px;
  text-decoration: none;
}
.right-navigation-links a:hover {
  color: #fff;
  background: transparent !important;
}
.right-navigation-links a.icon-about {
  font-size: 12px;
  padding-right: 10px;
  text-transform: uppercase;
}
.right-navigation-links a.icon-about:hover {
  color: #0da6e9;
  font-weight: bold;
}
.right-navigation-links a span {
  color: #fff;
}
.right-navigation-links a:hover {
  border-color: #f1bc31;
}
.right-navigation-links a:hover span {
  color: #fff;
}

/* User Drop Down */
.user-sign {
  text-transform: uppercase;
  padding: 20px 30px !important;
  font-weight: normal !important;
  border-radius: 1px !important;
  background-color: #0da5e8 !important;
  color: #000 !important;
  /* background: url("/public/images/header/dropdown-menu-bg.png") repeat !important; */
}
.user-sign .form-group {
  margin-bottom: 1rem;
}
.user-sign .form-group a.show {
  color: #0da5e8 !important;
}
.user-sign .form-group a {
  font-size: 13px;
  color: #000;
}
.user-sign .form-group a:hover {
  color: #fff;
}
.user-sign .form-group .btn-primary {
  font-size: 13px;
  color: #fff;
  background-color: #000;
  border-radius: 0px;
}
.user-sign .form-group .btn-primary:hover {
  font-size: 13px;
  color: #fff;
  background-color: #303030;
  border-radius: 0px;
}
.submituser {
  margin-left: 28px;
}
.user-sign .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.5;
  color: #495057;
  box-shadow: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.mobile-only {
  display: none;
}

/* Language Icons */
.language-drop {
  font-size: 8px;
  border-radius: 1px !important;
  background-color: #0da5e8 !important;
  color: #000 !important;
  margin-top: -3px !important;
}
span.flag:before,
span.flag:not(.icon) {
  display: inline-block;
  width: 16px;
  height: 11px;
}
span.flag:not(.icon) {
  margin: 0 0.5em 0 0;
  line-height: 11px;
  text-decoration: inherit;
  vertical-align: baseline;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
span.flag:before,
span.flag:not(.icon) {
  display: inline-block;
  width: 16px;
  height: 11px;
}

ul.flag-icon {
  margin: 0px;
  padding: 0px;
}

ul.flag-icon li {
  width: 100%;
  list-style-type: none;
  font-size: 13px;
  text-transform: uppercase;
  padding: 10px 50px 10px 20px !important;
}

ul.flag-icon li:hover {
  color: #fff;
  background-color: #006d9c !important;
}

span.flag-en {
  background-image: url("/public/images/header/language/en.png");
  background-repeat: no-repeat;
}
span.flag-es {
  background-image: url("/public/images/header/language/es.png");
  background-repeat: no-repeat;
}
span.flag-fr {
  background-image: url("/public/images/header/language/fr.png");
  background-repeat: no-repeat;
}
span.flag-it {
  background-image: url("/public/images/header/language/it.png");
  background-repeat: no-repeat;
}

span.flag-icon-en {
  background-image: url("/public/images/header/language/en.png");
  background-repeat: no-repeat;
}
span.flag-icon-es {
  background-image: url("/public/images/header/language/es.png");
  background-repeat: no-repeat;
}
span.flag-icon-fr {
  background-image: url("/public/images/header/language/fr.png");
  background-repeat: no-repeat;
}
span.flag-icon-it {
  background-image: url("/public/images/header/language/it.png");
  background-repeat: no-repeat;
}
.flag-icon {
  position: relative;
  display: inline-block;
  width: 100%;
  line-height: 1.5em;
}

/* ---------- Home Banner ---------- */
.homepageBanner {
  margin-top: 5rem;
}

/* ---------- Licensed Products Block ---------- */
.licensedproducts-cont {
  margin: 30px 20px;
}

/* ---------- Product Accessories Block ---------- */
.accessories-cont {
  margin: 50px 0px 20px 0px;
}
.swapimg-container-nb img {
  width: 70%;
  height: 70%;
}
.paacenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

/* ---------- Register and Discover Block ---------- */
.randd-cont {
  overflow: hidden;
  padding: 100px 0px 50px 0px;
  margin: 0px 0px;
  background: #e2e2e2 url("/public/images/home/randd/background-Grupo-162.png")
    right bottom no-repeat;
}
.randd-cont .randd-width {
  width: 80%;
}

.randd-cont-heading {
  font-size: 30px;
  color: #0da6e9;
  font-weight: bold;
  line-height: 2.5rem;
  margin-bottom: 40px;
}

ul.randd-cont-services {
  margin: 0px;
  padding: 0px;
}

ul.randd-cont-services li {
  float: left;
  font-size: 20pt;
  list-style-type: none;
  margin: 0px 0px 40px 0px;
  padding: 0px 80px 0px 0px;
  width: 50%;
  line-height: 2rem;
}

ul.randd-cont-services li {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #000;
}

ul.randd-cont-services li:hover {
  color: #0da6e9;
}

ul.randd-cont-services li img {
  margin-right: 15px;
  width: 65px;
}

.dos-cont {
  width: 100%;
  background: url(/public/images/home/randd/skyblue-bg.png) repeat;
  padding: 7%;
  display: block;
  justify-content: end;
}

.dos-cont .dos-heading {
  font-size: 22px;
  color: #000;
  font-weight: bold;
  line-height: 2rem;
}

.dos-cont .dos-subheading {
  font-size: 22px;
  color: #fff;
  font-weight: bold;
  line-height: 2rem;
  margin-bottom: 20px;
}

ul.dos {
  margin: 0px;
  overflow: hidden;
  padding: 0px;
}

ul.dos li {
  background-color: #fff;
  font-size: 16pt;
  font-weight: bold;
  list-style-type: none;
  margin: 0px 0px 30px 0px;
  padding: 15px 1px 15px 15px;
  width: 100%;
  line-height: 2rem;
}

ul.dos li a {
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 2rem;
}

ul.dos li a:hover {
  color: #0da6e9;
}

/* ---------- Our Brands Block ---------- */
/* .ourbrands-parent {
  margin: 60px 20px;
  margin-bottom: 10px;
} */

.ourbrands {
  margin: 0px 0px 20px 0px !important;
}

.ourbrands-cont {
  width: 85%;
  overflow: hidden;
  margin: 0px auto !important;
}

.ourbrands-cont img {
  width: 100%;
}

.ourbrands-cont {
  width: 85%;
  overflow: hidden;
  margin: 0px auto !important;
}

.brand-parent {
  position: relative;
  display: inline-block;
  background-size: cover;
}

.brand-parent img {
  display: block;
}

.brand-parent:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  transition: 0.3s;
}

.brand-parent:hover:after {
  background: rgba(0, 0, 0, 0.8);
}

.brand-parent:hover .brand-child {
  opacity: 1;
}

.brand-child {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, -50%);
  z-index: 5;
  color: white;
  opacity: 0;
  transition: 0.5s;
  line-height: 2.5rem;
}

/* ---------- Product Categories Block ---------- */
.product-categories-cont button.slick-prev,
.product-categories-cont button.slick-next {
  display: none !important;
}
.product-categories-cont {
  padding: 50px 0px 80px 0px;
  background: url("/public/images/home/product-categories/background-Grupo-81-left.png")
      left bottom no-repeat,
    url("/public/images/home/product-categories/background-Grupo-81-right.png")
      right top no-repeat;
  overflow: hidden;
}
.product-categories-cont-two {
  padding: 10px 0px;
  overflow: hidden;
}
.product-categories-top {
  width: 100%;
  height: 9px;
  background: url("/public/images/home/product-categories/lp-top.png") repeat-x;
}

.product-categories-bottom {
  width: 100%;
  height: 10px;
  background: url("/public/images/home/product-categories/lp-bottom.png")
    repeat-x;
}
.card-img-top {
  border-radius: 0px !important;
}

.graybg-sm {
  width: 100%;
  overflow: hidden;
  position: relative !important;
}

.graybg-sm img {
  width: 100% !important;
}

.graybg-bg {
  width: 100%;
  overflow: hidden;
  position: relative !important;
}

.graybg-bg img {
  width: 100% !important;
}

.lptcont {
  bottom: 10px;
  left: 20px;
  position: absolute !important;
}

.lptcont h2 {
  font-size: 50px;
  font-weight: bold;
}
.lptcont p.subheading {
  font-size: 34px;
  margin-bottom: 0px;
}

.lptbg {
  font-size: 20px;
  width: 100%;
  padding: 5px;
  margin-bottom: 0px;
  background-color: #fff;
  background: url(/public/images/home/products/lg-transparent.png) repeat;
}

.lptbg a {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
}

.lptbg a:hover {
  color: #0da6ea;
}
.lptbg a p {
  margin: 0px;
}

/* ---------- Outstanding Products Block ---------- */
.card .cartwrap {
  position: relative;
  z-index: 3;
  color: #fff;
  opacity: 0;
  transform: translateY(0px);
  transition: 0.5s all;
  background-color: #000;
  padding: 0px;
}
.outstanding-cont {
  margin: 0px 0px;
  padding: 10px 0px 25px 0px;
  background: url("/public/images/home/product-categories/background-Grupo-81-left.png")
      left bottom no-repeat,
    url("/public/images/home/product-categories/background-Grupo-81-right.png")
      right top no-repeat,
    url("/public/images/home/product-categories/lp-bottom.png") left top
      repeat-x,
    url("/public/images/home/product-categories/lp-top.png") left bottom
      repeat-x;
  overflow: hidden;
}

.outstanding-cont-two {
  margin: 0px 0px;
  padding: 10px 0px 25px 0px;
  background: url("/public/images/home/product-categories/lp-bottom.png") left
      top repeat-x,
    url("/public/images/home/product-categories/lp-top.png") left bottom
      repeat-x;
  overflow: hidden;
}

.card:hover .cartwrap {
  opacity: 1;
  transform: translateY(0px);
}
.cartwrap-cont {
  background-color: #000;
  overflow: hidden;
}

.cartwrap {
  width: 100%;
  background-color: #000;
}
.cartwrap-center .form-control {
  margin: 0px;
  padding: 0px;
  width: 20px;
}
.cartwrap-left,
.cartwrap-right {
  width: 20px;
  height: 100%;
}

.cartwrap-left-one {
  float: left;
}
.cartwrap-left-one .cart-btn {
  padding: 6px 5px 6px 5px;
}

.cartwrap-left-three {
  float: left;
  width: 125px;
}
.cartwrap-left-three .cart-btn {
  padding: 6px 5px 6px 5px;
  width: 125px;
  text-align: left;
  text-transform: uppercase;
}

.cartwrap-left-six {
  float: left;
  width: 129px;
}
.cartwrap-left-six .cart-btn {
  padding: 6px 5px 6px 5px;
  width: 129px;
  text-align: left;
  text-transform: uppercase;
  border-radius: 0px;
  background-color: #000;
}
.cartwrap-left-six .cart-btn:hover {
  background-color: #0da5e8;
}

.cartwrap-left-seven {
  float: left;
  width: 115px;
}
.cartwrap-left-seven .cart-btn {
  padding: 6px 5px 6px 5px;
  width: 185px;
  text-align: left;
  text-transform: uppercase;
  border-radius: 0px;
  background-color: #000;
}
.cartwrap-left-seven .cart-btn:hover {
  background-color: #0da5e8;
}

.cartwrap-left-five {
  float: left;
  width: 217px;
}
.cartwrap-left-five .cart-btn {
  padding: 6px 5px 6px 5px;
  width: 130px;
  text-align: left;
  text-transform: uppercase;
  border-radius: 0px;
  background-color: #000;
}
.cartwrap-left-five .cart-btn:hover {
  background-color: #0da5e8;
}

.cartwrap-left-one button,
.cartwrap-left-three button {
  border-radius: 0px !important;
  background-color: #000;
}
.cartwrap-left-one button:hover,
.cartwrap-left-three button:hover {
  border-radius: 0px !important;
  background-color: #0b81b4;
}

.cartwrap-left-two {
  float: left;
  text-align: center;
}

.cartwrap-center {
  float: left;
  height: 31px;
  width: 52px;
}
.cartwrap-center input[type="text"] {
  float: left;
  width: 50px;
  height: 34px;
  text-align: center;
  border-radius: 0px;
  border: 1px solid #000;
  margin: 1px;
}

.form-control-two {
  font-size: 14px;
  border-radius: 0px;
  font-weight: normal;
  width: 100%;
  text-align: center;
}

.cartwrap-right {
  float: right;
}

.btn-black {
  background-color: #000 !important;
  color: #fff !important;
  padding: 8px 14px 0px 18px !important;
  border-radius: 0px !important;
  font-size: 14px !important;
}

.btn-black-two {
  background-color: #000 !important;
  color: #fff !important;
  padding: 8px 4px !important;
  border-radius: 0px !important;
  font-size: 12px !important;
}
.btn-black-two:hover {
  background-color: #0da6e9 !important;
  color: #fff !important;
  padding: 8px 4px !important;
  border-radius: 0px !important;
  font-size: 12px !important;
}

.btn-dgray {
  background-color: rgb(148, 148, 148) !important;
  color: #fff !important;
  padding: 8px 14px 5px 18px !important;
  border-radius: 0px !important;
  font-size: 14px !important;
}

.item-count {
  width: 3000px;
  text-align: center;
  border-radius: 0px !important;
  height: 36px;
}
.cardmargins {
  margin: 0px 10px;
  border-radius: 0px !important;
  background: transparent !important;
}

.cardmargins-bg {
  width: 80%;
  margin: auto;
  border-radius: 0px !important;
  background: #fff !important;
}

.cardmargins a {
  color: #757575;
  font-weight: bold;
  text-decoration: none;
}

.cardmargins a:hover {
  color: #0da5e8;
}

/* .swapimg-container {
  position: relative;
  overflow: hidden;
  width: 75%;
  max-width: 250px;
  min-width: 250px;

  margin: 0 auto;
} */

.swapimg-container img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
}

.HiShoppingCart-icon {
  width: 20px;
  height: 20px;
}

/* .swapimg-container img {
  width: 100%;
  height: 100%;
} */

.fa-plus .fa-minus {
  width: 50px !important;
}

.swapimg-container .top-img {
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0;
  transition: all 0.7s ease;
}

.swapimg-container .top-img:hover {
  opacity: 1;
}

.swapimg-container-nb {
  position: relative;
  margin-bottom: 10px;
  font-size: 25px;
}

.swapimg-container-nb .top-img {
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0;
  transition: all 0.7s ease;
  border-radius: 0px;
}

.swapimg-container-nb .top-img:hover {
  opacity: 1;
  border: 1px solid #303030;
  background-color: #fff;
}

.swapimgtwo-container-nb {
  position: relative;
  margin-bottom: 10px;
  font-size: 25px;
}
.swapimgtwo-container-nb img.card-img-top {
  width: 70%;
}
/* 
.swapimgtwo-container-nb .top-img {
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0;
  transition: all 0.7s ease;
}
.swapimgtwo-container-nb .top-img:hover {
  opacity: 1;
  border: 1px solid #303030;
  background-color: #fff;
} */

/* .randd-cont {
  overflow: hidden;
  padding: 100px 0px 70px 0px;
  margin: 0px 0px;
  background: #e2e2e2 url("/public/images/home/randd/background-Grupo-162.png")
    right bottom no-repeat;
}

.randd-cont .randd-width {
  width: 80%;
} */

.product-single-qty .bootstrap-touchspin .form-control {
  width: 2.7em;
  padding: 0px;
  color: #222529;
  font-size: 1.4rem;
  font-family: Poppins, sans-serif;
  text-align: center;
}

.product-detail-wrap-two {
  margin: 10px 0px;
  overflow: hidden;
  width: 100%;
}
.product-detail-wrap-two .bg {
  background-color: #0da5e8;
  border-bottom: 2px solid #0da5e8;
  border-top: 2px solid #0da5e8;
  margin: 0 0 2px;
  padding: 0px 4px;
  width: 100%;
}

.product-detail-wrap-two .row p.customertype {
  width: 40%;
  float: left;
  font-size: 12px;
  font-weight: bold;
  color: #000;
  text-align: left;
  padding: 0px;
  margin: 0px;
  line-height: 1rem;
}
.product-detail-wrap-two .row .reducedprice {
  width: 28%;
  float: left;
  font-size: 12px;
  color: #ff0000;
  font-weight: bold;
  padding: 5px 0px 0px 0px;
  text-decoration: line-through;
  border-right: 2px solid #fff;
  text-align: center;
}
.product-detail-wrap-two .row .reducedprice-two {
  width: 33%;
  float: left;
  color: #ff0000;
  background-color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 0px 0px 0px;
  text-decoration: line-through;
  border-right: 2px solid #fff;
  text-align: center;
}
.reducedprice-three {
  color: #ff0000;
  padding: 5px 10px 0px 0px;
  text-decoration: line-through;
}

.product-detail-wrap-two .row .price {
  width: 27%;
  float: left;
  background-color: #0da6ea;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 0px 0px 0px;
  text-align: center;
}
.product-detail-wrap-two .row .price-two {
  width: 27%;
  float: left;
  background-color: #0da6ea;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 0px 0px 0px;
  text-align: center;
}

.product-detail-wrap-two .row .price span.currency {
  font-size: 12px;
  font-weight: normal;
  padding-left: 5px;
}

.product-detail-wrap {
  margin: 10px;
  overflow: hidden;
  width: 95%;
}
.smallwidth {
  margin: 0px 0px 10px 0px !important;
  overflow: hidden;
  width: 250px !important;
}

.slick-slider {
  width: 90% !important;
  margin: 0px auto !important;
}

.product-single-qty {
  background-color: #000;
  padding: 2px 0px 2px 2px;
  float: left;
}

.product-detail-wrap .row {
  margin: 0px 0px 2px 0px;
  /* border:1px solid #0da6ea; */
}

.product-detail-wrap .bg {
  background-color: #0da5e8;
  border-top: 2px solid #0da5e8;
  border-bottom: 2px solid #0da5e8;
  padding: 0px 5px;
  margin: 0px 0px 2px 0px;
  width: 99%;
}

.product-detail-wrap .row .title {
  width: 44%;
  float: left;
  font-size: 12px;
  color: #0da6ea;
  text-align: left;
  padding: 0px;
  text-overflow: ellipsis; /* enables ellipsis */
  white-space: nowrap; /* keeps the text in a single line */
  overflow: hidden;
}

.product-detail-wrap .row .skuid {
  width: 27%;
  float: left;
  background-color: #e6e6e6;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 0px 0px 0px;
  text-align: center;
}

.product-detail-wrap .row p.desc {
  width: 72%;
  float: left;
  font-size: 12px;
  font-weight: bold;
  color: #000;
  text-align: left;
  padding: 0px;
  margin: 0px;
  line-height: 1rem;
}
.product-detail-wrap .row p.customertype {
  width: 44%;
  float: left;
  font-size: 12px;
  font-weight: bold;
  color: #000;
  text-align: left;
  padding: 0px;
  margin: 0px;
  line-height: 1rem;
}
.product-detail-wrap .row .reducedprice {
  width: 28%;
  float: left;
  color: #ff0000;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 0px 0px 0px;
  text-decoration: line-through;
  border-right: 2px solid #fff;
  text-align: center;
}
.product-detail-wrap .row .reducedprice-two {
  width: 29%;
  float: left;
  color: #ff0000;
  background-color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 0px 0px 0px;
  text-decoration: line-through;
  border-right: 2px solid #fff;
  text-align: center;
}
.product-detail-wrap .row .price {
  width: 27%;
  float: left;
  background-color: #0da6ea;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 0px 0px 0px;
  text-align: center;
}
.product-detail-wrap .row .price-two {
  width: 27%;
  float: left;
  background-color: #0da6ea;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 0px 0px 0px;
  text-align: center;
}

.product-detail-wrap .row .price span.currency {
  font-size: 12px;
  font-weight: normal;
  padding-left: 5px;
}
/* ---------- Warner Page Block ---------- */
.warner-licensed-cont {
  margin: 0px 0px 40px 0px;
  overflow: hidden;
}

/* Warner Page FAQ Block */
.faq-cont-two {
  overflow: hidden;
  padding: 50px 0px 70px 0px;
  margin: 0px 0px;
}
.faq-cont-two .faq-width {
  width: 70%;
  margin: 0px auto;
}

/* ---------- FAQ Block ---------- */
.faq-cont-top {
  background: #e2e2e2 url("/public/images/home/faq/faq-shadow.png") left top
    repeat;
}

.faq-cont {
  overflow: hidden;
  padding: 50px 0px 70px 0px;
  margin: 0px 0px;
  background-color: #e2e2e2;
  background: #e2e2e2 url("/public/images/home/faq/goku.png") right top
    no-repeat;
}

.faq-cont .faq-width {
  width: 70%;
  margin: 0px auto;
}

.faq-cont .faq-width .accordion-cont {
  margin: 10px 0px;
}

.faqheading {
  color: #0da6e9 !important;
}
.Mui-disabled {
  background-color: #a8a8a8 !important;
}

.MuiPaper-elevation {
  background-color: transparent !important;
}

.MuiAccordionSummary-expandIconWrapper {
  color: #0da5e8 !important;
  font-size: 22px;
}

.faq-cont .faq-width .accordio-cont .MuiAccordionSummary-expandIconWrapper {
  color: #0da5e8;
  border-bottom: 1px solid #ccc;
}

/* ---------- Footer Block ---------- */
nt-two {
  bottom: 10px;
  position: absolute !important;
}

.lptcont-two h2 {
  bottom: 10px;
  font-size: 50px;
  font-weight: bold;
  color: #000;
}

.lptbg-two {
  font-size: 20px;
  width: 100%;
  padding: 5px;
  margin-bottom: 0px;
  background-color: #fff;
  background: url(/public/images/home/products/lg-transparent.png) repeat;
}

.lptbg-two a {
  text-decoration: none;
  color: #000;
  font-weight: bold;
}

.lptbg-two a:hover {
  color: #0da6ea;
}
.footer-container {
  width: 100%;
  overflow: hidden;
  color: #fff;
  font-size: 15px;
}
.fcw {
  width: 95%;
  margin: 0px auto !important;
}
.footer-container .footer-top {
  width: 100%;
  height: 29px;
  background: url(/public/images/footer/footer-top.jpg) repeat-x;
}

.footer-container .footer-mid {
  width: 100%;
  background-color: #5d5d5d;
  padding-bottom: 50px;
}

.footer-phone {
  font-size: 36px;
  padding-bottom: 0px;
  border-bottom: 2px solid #fff;
  margin-bottom: 5px;
}

.footer-company {
  font-size: 16px;
  font-weight: bold;
}

.footer-address {
  font-size: 16px;
  white-space: pre;
}

.footer-heading {
  color: #0da5e8;
  font-size: 22px;
  font-weight: bold;
  margin-top: 20px;
  border-bottom: 2px solid #fff;
}

.footer-list {
  font-size: 16px;
  padding: 10px 0px 0px 0px;
}

.footer-list .social-cont {
  margin: 10px 0px 0px 0px !important;
}

ul.social-media {
  margin: 0px;
  padding: 0px;
}

ul.social-media li {
  float: left;
  list-style-type: none;
  margin: 0px 30px 20px 0px !important;
}

ul.social-media li a {
  text-decoration: none;
}

ul.social-media li a:hover {
  color: #0da5e8;
}

ul.social-media li a svg {
  width: 45px;
  height: 45px;
}

.footer-list a {
  text-decoration: none;
  color: #fff;
}

.footer-list a:hover {
  color: #0da5e8;
}

.footer-container .footer-bottom {
  width: 100%;
  height: 35px;
  background-color: #000;
}

/* ---------- BatmanPin Block ---------- */
.btn-wrapper .icon-batman .icon-batman-btn {
  width: 5rem;
  height: 100%;
}

.pin {
  position: fixed;
  right: 2rem;
  bottom: 3rem;
  box-sizing: border-box;
  border-radius: 100px;
  background: white;
  box-shadow: 0 1rem 5rem -2rem rgba(0, 0, 0, 0.5);
  transition: transform 100ms linear;
  z-index: 3;
}

.btn-wrapper .tooltip {
  position: absolute;
  top: 0;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.btn-wrapper .icon-batman:hover .tooltip {
  top: -2.5rem;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.tooltip {
  z-index: 1070;
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  line-break: auto;
}

/* Reset invalid token */

.invalidToken {
  margin: 8rem 0 20rem 2rem;
  width: 95%;
}

/* 
  *
  *
*/

/* ---------- Media Queries Block ---------- */
@media screen and (min-width: 320px) and (max-width: 575px) {
  .breadcrumb-cont {
    padding: 6rem 0px 2rem 0px;
  }
  .invalidToken {
    margin: 15rem 0 10rem 1rem;
    width: 90%;
  }
  .swapimg-container img {
    width: 50%;
  }
  
  .col-megamenu {
    grid-template-columns: repeat(1, 150px);
  }
  .c-width {
    width: 100%;
  }
  .btwob {
    margin: 160px auto 50px auto;
  }
  .register-sub-heading {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
  .multi-cont ol {
    flex-direction: column;
    width: 90%;
  }
  .billing-cont {
    width: 100%;
  }
  .blackoutline {
    font-size: 12px;
    padding: 4px 40px;
  }
  
  .lptcont-four {
    width: 550px;
    bottom: -10px;
    left: 10px;
    overflow: hidden;
  }
  .blackbutton {
    font-size: 12px;
    margin: 0px 0px;
    padding: 5px 30px;
  }
  .lptcont-four .section-heading {
    font-size: 20px;
  }
  .lptcont-four p.section-paragraph {
    font-size: 12px;
  }
  .lptcont-four p.section-paragraph p {
    margin: 0px;
  }
  .slick-prev {
    left: -15px !important;
  }
  .slick-next {
    right: -15px !important;
  }
  .lptcont-three {
    bottom: -13px;
  }
  .lptcont-three h2 {
    font-size: 1.8rem;
  }
  .lptcont-three p.subheading {
    font-size: 1.8rem;
  }
  .lptcont-three .lptbg a {
    font-size: 0.8rem;
  }
  .cardmargins a {
    font-size: 9px;
  }
  .page-body-container {
    margin-top: 185px;
  }
  .pro-qty-cont {
    float: left;
    clear: both;
  }
  .checkoutbutton {
    padding: 5px 0px;
  }
  .ourbrands {
    margin: 0px 0px 0px 0px !important;
  }
  .section-heading-left {
    font-size: 15px;
    line-height: 1rem;
  }
  .section-paragraph-left {
    font-size: 12px;
  }
  .banner-vh-center-text-bg .center .anchorbg {
    margin-top: 0px;
  }
  .banner-vh-center-text-bg .center p {
    font-size: 20px;
  }
  .banner-v-center-text-bg .left {
    width: 90%;
    left: 50%;
    top: 50%;
  }
  .banner-v-center-text-bg .left h2 {
    font-size: 24px;
    font-weight: bold;
  }
  .banner-v-center-text-bg .left button {
    font-size: 14px;
    margin: 0px 0px;
    padding: 10px 20px;
  }
  .banner-v-center-text-bg .left p {
    font-size: 12px;
    line-height: 1rem;
  }
  .banner-vh-center-text-bg .center h2 {
    font-size: 40px;
  }
  .banner-vh-center-text-bg .center .anchorbg a {
    font-size: 15px;
  }
  .banner-vh-center-text-bg .center .anchorbg {
    padding: 0px;
  }
  .ProDGSpecial-container {
    margin: 40px 20px;
  }
  .ProDGSpecial-content .heading {
    font-size: 24px;
    line-height: 1.5rem;
  }
  .banner-v-center-hstext-bg .left p {
    font-size: 20px;
  }
  .banner-v-center-hstext-bg .left {
    left: 35%;
    top: 47%;
  }
  .banner-v-center-hstext-bg .left h2 {
    font-size: 20px;
  }
  .banner-v-center-hstext-bg .left .anchorbg {
    width: 100%;
    padding: 0px;
    margin-top: 0px;
  }
  .banner-v-center-hstext-bg .left .anchorbg a {
    font-size: 14px;
    line-height: 1rem;
  }
  .eh-cont .eh-col {
    padding: 0px 0px 20px 0px;
  }
  .lptcont-two h2 {
    font-size: 22px;
  }
  .lptbg-two {
    font-size: 12px;
  }
  .main-banner-heading {
    font-size: 26px;
    margin-bottom: 0px;
  }
  .main-banner-subheading {
    font-size: 14px;
    line-height: 1rem;
  }
  .container-two {
    margin: 0px 0px;
    padding: 0px 10px;
    position: relative;
    z-index: 9999;
  }
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: block;
    margin: 0px;
    padding: 10px 20px;
    background-color: #000;
  }
  .homepageBanner {
    margin-top: 175px;
  }
  .lptcont {
    bottom: -3px;
  }
  .lptcont h2 {
    font-size: 30px;
    margin: 0px;
  }
  .lptcont p.subheading {
    font-size: 24px;
  }
  .lptbg {
    font-size: 14px;
  }
  .randd-cont-heading {
    font-size: 20px;
    line-height: 1.5rem;
    margin-bottom: 20px;
  }
  ul.randd-cont-services li {
    font-size: 15px;
    width: 100%;
    margin-bottom: 20px;
  }
  ul.randd-cont-services li img {
    margin-right: 15px;
    width: 34px;
  }
  .dos-cont .dos-heading {
    font-size: 20px;
  }
  .dos-cont .dos-subheading {
    font-size: 20px;
  }
  ul.dos li a img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }
  .section-heading {
    font-size: 15px;
    line-height: 1.3rem;
    width: 95%;
  }
  .section-paragraph {
    font-size: 11px;
    width: 97%;
  }
  .licensedproducts-cont {
    margin: 10px 20px;
  }
  .accessories-cont {
    margin: 10px 0px 20px 0px;
  }
  .product-categories-cont {
    padding: 0px 0px 0px 0px;
    background: none !important;
  }
  .randd-cont {
    padding: 10px 0px 10px 0px;
  }
  .outstanding-cont {
    padding: 10px 0px 10px 0px;
    background: none;
  }
  .faq-cont {
    padding: 10px 0px 10px 0px;
  }
  .product-supplier-cont .heading {
    font-size: 26px;
  }
  .myaccount-page-body {
    margin-top: 135px;
  }
  ul.product-list li .content .inner {
    width: 137px;
  }
  ul.pagination {
    padding-left: 20px !important;
  }
  ul.pagination li {
    height: 20px !important;
    width: 20px !important;
    font-size: 12px;
  }
  ul.pagination li a {
    padding: 0px !important;
  }
  .pagination-cont {
    text-align: center;
    margin-top: 20px !important;
    justify-content: center;
    display: flex;
  }
  .product-grid {
    margin-left: 12px !important;
  }
}
@media screen and (min-width: 576px) and (max-width: 768px) {
  .invalidToken {
    margin: 12rem 0 10rem 2rem;
    width: 90%;
  }
  .breadcrumb-cont {
    padding: 5rem 0px 2rem 0px;
  }
  .swapimg-container img {
    width: 50%;
  }
  
  .btwob {
    margin: 160px auto 50px auto;
  }
  
  .multi-cont ol {
    flex-direction: column;
    width: 90%;
  }
  .billing-cont {
    width: 100%;
  }
  .blackoutline {
    font-size: 12px;
    padding: 4px 40px;
  }
  ul.product-list li .content .inner {
    width: 137px;
  }
  .myaccount-page-body {
    margin-top: 135px;
  }
 
  .product-supplier-cont .heading {
    font-size: 26px;
  }
  .banner-v-center-text-bg .left {
    width: 55%;
    left: 33%;
  }
  .lptcont-four {
    width: 550px;
    bottom: -9px;
    left: 0px;
    overflow: hidden;
  }
  .blackbutton {
    font-size: 12px;
    margin: 0px 0px;
    padding: 5px 30px;
  }
  .lptcont-four .section-heading {
    font-size: 20px;
  }
  .lptcont-four p.section-paragraph {
    font-size: 12px;
    overflow: hidden;
  }
  .lptcont-four p.section-paragraph p {
    margin: 0px;
  }

  .lptcont-three {
    bottom: 0px;
  }
  .lptcont-three h2 {
    font-size: 1.8rem;
  }
  .lptcont-three p.subheading {
    font-size: 1.8rem;
  }
  .lptcont-three .lptbg a {
    font-size: 0.8rem;
  }
  .page-body-container {
    margin-top: 160px;
  }
  .ourbrands {
    margin: 0px 0px 0px 0px !important;
  }
  .section-heading-left {
    font-size: 18px;
    line-height: 1rem;
  }
  .section-paragraph-left {
    font-size: 15px;
  }
  .banner-vh-center-text-bg .center p {
    font-size: 24px;
  }
  .banner-vh-center-text-bg .center h2 {
    font-size: 45px;
  }
  .banner-vh-center-text-bg .center .anchorbg a {
    font-size: 17px;
  }
  .ProDGSpecial-container {
    margin: 40px 20px;
  }
  .ProDGSpecial-content .heading {
    font-size: 24px;
    line-height: 1.5rem;
  }
  .banner-v-center-hstext-bg .left p {
    font-size: 26px;
  }
  .banner-v-center-text-bg .left h2 {
    font-size: 17px;
  }
  .banner-v-center-text-bg .left p {
    font-size: 10px;
    line-height: 0.8rem;
  }
  .banner-v-center-text-bg .left button {
    font-size: 11px;
    margin: 3px 0px;
    padding: 10px 20px;
  }
  .banner-v-center-hstext-bg .left {
    left: 35%;
    top: 47%;
  }
  .banner-v-center-hstext-bg .left h2 {
    font-size: 26px;
  }
  .banner-v-center-hstext-bg .left .anchorbg {
    width: 100%;
    padding: 0px;
    margin-top: 0px;
  }
  .banner-v-center-hstext-bg .left .anchorbg a {
    font-size: 18px;
    line-height: 1rem;
  }
  .eh-cont .eh-col {
    padding: 0px 0px 20px 0px;
  }
  .lptcont-two h2 {
    font-size: 30px;
  }
  .lptbg-two {
    font-size: 12px;
  }
  .main-banner-heading {
    font-size: 26px;
    margin-bottom: 0px;
  }
  .main-banner-subheading {
    font-size: 14px;
    line-height: 1rem;
  }
  .container-two {
    margin: 0px 0px;
    padding: 0px 10px;
  }
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: block;
    margin: 0px;
    padding: 10px 20px;
    background-color: #000;
  }
  .homepageBanner {
    margin-top: 145px;
  }
  .lptcont {
    bottom: -3px;
  }
  .lptcont h2 {
    font-size: 30px;
    margin: 0px;
  }
  .lptcont p.subheading {
    font-size: 24px;
  }

  .lptbg {
    font-size: 14px;
  }

  ul.randd-cont-services li {
    font-size: 25px;
    width: 100%;
  }
  ul.randd-cont-services li img {
    margin-right: 15px;
    width: 52px;
    width: 52px;
  }
  .dos-cont .dos-heading {
    font-size: 20px;
  }
  .dos-cont .dos-subheading {
    font-size: 20px;
  }
  ul.dos li a img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }
  .section-heading {
    font-size: 18px;
    line-height: 1.3rem;
    width: 95%;
  }
  .section-paragraph {
    font-size: 11px;
    width: 97%;
  }
  .licensedproducts-cont {
    margin: 10px 20px;
  }
  .accessories-cont {
    margin: 10px 0px 20px 0px;
  }
  .product-categories-cont {
    padding: 10px 0px 10px 0px;
    background: none !important;
  }
  .randd-cont {
    padding: 10px 0px 10px 0px;
  }
  .outstanding-cont {
    padding: 10px 0px 10px 0px;
    background: none;
  }
  .faq-cont {
    padding: 10px 0px 10px 0px;
  }
}
@media screen and (min-width: 769px) and (max-width: 992px) {
  .invalidToken {
    margin: 12rem 0 10rem 2rem;
    width: 90%;
  }
  .breadcrumb-cont {
    padding: 5rem 0px 2rem 0px;
  }
  .swapimg-container img {
    width: 50%;
  }
  .btwob {
    margin: 160px auto 50px auto;
  }
  
  .multi-cont ol {
    flex-direction: column;
    width: 90%;
  }
  .billing-cont {
    width: 100%;
  }
  .myaccount-page-body {
    margin-top: 135px;
  }
 
  .product-supplier-cont .heading {
    font-size: 28px;
  }
  .banner-v-center-text-bg .left {
    width: 55%;
    left: 33%;
  }
  .lptcont-four {
    width: 440px;
    bottom: -10px;
    left: 0px;
    overflow: hidden;
  }
  .blackbutton {
    font-size: 12px;
    margin: 5px 0px;
    padding: 10px 30px;
  }
  .lptcont-four .section-heading {
    font-size: 20px;
  }
  .lptcont-four p.section-paragraph {
    font-size: 12px;
    overflow: hidden;
  }
  .lptcont-four p.section-paragraph p {
    margin: 0px;
  }
  .lptcont-three {
    bottom: -10px;
  }
  .lptcont-three h2 {
    font-size: 2rem;
  }
  .lptcont-three p.subheading {
    font-size: 2rem;
  }

  .product-details .product-title a {
    width: 155px;
  }
  .page-body-container {
    margin-top: 150px;
  }
  ul.dos li {
    padding: 15px 1px 15px 10px;
  }
  .ourbrands {
    margin: 0px 0px 0px 0px !important;
  }
  .section-heading-left {
    font-size: 18px;
    line-height: 1.3rem;
  }
  .section-paragraph-left {
    font-size: 15px;
  }
  .banner-v-center-hstext-bg .left h2 {
    font-size: 40px;
  }
  .banner-v-center-hstext-bg .left {
    left: 37%;
  }
  .banner-vh-center-text-bg .center h2 {
    font-size: 45px;
  }
  .banner-vh-center-text-bg .center .anchorbg a {
    font-size: 17px;
  }
  .lptcont h2 {
    font-size: 34px;
    margin: 0px;
  }
  .banner-v-center-hstext-bg .left p {
    font-size: 37px;
  }

  .banner-v-center-text-bg .left h2 {
    font-size: 30px;
  }
  .banner-v-center-text-bg .left p {
    font-size: 13px;
    line-height: 0.8rem;
  }
  .banner-v-center-text-bg .left button {
    margin: 10px 0px;
    padding: 10px 40px;
  }

  .banner-v-center-hstext-bg .left .anchorbg {
    width: 100%;
    padding: 5px;
    margin-top: 10px;
  }
  .banner-v-center-hstext-bg .left .anchorbg a {
    font-size: 18px;
    line-height: 1rem;
  }
  .eh-cont .eh-col {
    padding: 0px 0px 20px 0px;
  }
  .lptcont-two h2 {
    font-size: 36px;
  }
  .lptbg-two {
    font-size: 20px;
  }
  .main-banner-heading {
    font-size: 46px;
    margin-bottom: 0px;
  }
  .main-banner-subheading {
    font-size: 24px;
    line-height: 1.5rem;
  }
  .container-two {
    margin: 0px 0px;
    padding: 0px 10px;
  }
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: block;
    margin: 0px;
    padding: 10px 20px;
    background-color: #000;
  }
  .homepageBanner {
    margin-top: 134px;
  }
  ul.randd-cont-services li {
    font-size: 25px;
    width: 100%;
  }
  ul.randd-cont-services li img {
    margin-right: 15px;
    width: 52px;
    width: 52px;
  }
  .dos-cont .dos-heading {
    font-size: 20px;
  }
  .dos-cont .dos-subheading {
    font-size: 20px;
  }
  ul.dos li a {
    font-size: 14px;
    line-height: 1rem;
  }
  ul.dos li a img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .footer-phone {
    font-size: 33px;
  }
  .section-heading {
    font-size: 20px;
    line-height: 1.3rem;
    width: 95%;
  }
  .section-paragraph {
    font-size: 11px;
    width: 97%;
  }
  .randd-cont .randd-width {
    width: 90%;
  }
  .licensedproducts-cont {
    margin: 10px 20px;
  }
  .accessories-cont {
    margin: 10px 0px 20px 0px;
  }
  .product-categories-cont {
    padding: 10px 0px 10px 0px;
    background: none !important;
  }
  .randd-cont {
    padding: 10px 0px 10px 0px;
  }
  .outstanding-cont {
    padding: 0px 0px 0px 0px;
    background: none;
  }
  .faq-cont {
    padding: 10px 0px 10px 0px;
  }
  .ProDGSpecial-content .heading {
    font-size: 24px;
    line-height: 2rem;
  }
}
@media screen and (min-width: 993px) and (max-width: 1200px) {
  .invalidToken {
    margin: 12rem 0 10rem 2rem;
    width: 90%;
  }
  .breadcrumb-cont {
    padding: 4rem 0px 2rem 0px;
  }
  .swapimg-container img {
    width: 75%;
  }

  .btwob {
    margin: 160px auto 50px auto;
  }
  .multi-cont ol {
    flex-direction: column;
    width: 90%;
  }
  .billing-cont {
    width: 100%;
  }
  .myaccount-page-body {
    margin-top: 135px;
  }
  
  .product-supplier-cont .heading {
    font-size: 32px;
  }
  .ProDGSpecial-content .heading {
    font-size: 24px;
    line-height: 2rem;
  }
  .lptcont-four {
    width: 440px;
    bottom: 20px;
    left: 20px;
    overflow: hidden;
  }
  .blackbutton {
    font-size: 12px;
    margin: 5px 0px;
    padding: 10px 40px;
  }
  .lptcont-four .section-heading {
    font-size: 20px;
  }
  .lptcont-four p.section-paragraph {
    font-size: 14px;
    overflow: hidden;
  }
  .lptcont-four p.section-paragraph p {
    margin: 0px;
  }
  .page-body-container {
    margin-top: 150px;
  }
  .ourbrands {
    margin: 0px 0px 0px 0px !important;
  }
  .section-heading-left {
    font-size: 23px;
    line-height: 1.5rem;
  }
  .section-paragraph-left {
    font-size: 18px;
  }
  .banner-v-center-hstext-bg .left {
    left: 33%;
  }
  .banner-v-center-hstext-bg .left p {
    font-size: 40px;
    line-height: 1.5rem;
  }
  .banner-v-center-text-bg .left {
    width: 50%;
    left: 32%;
    top: 51%;
  }
  .banner-v-center-text-bg .left h2 {
    font-size: 30px;
  }
  .banner-v-center-text-bg .left p {
    font-size: 16px;
    line-height: 1.5rem;
  }
  .banner-v-center-text-bg .left button {
    margin: 10px 0px;
    padding: 10px 40px;
  }

  .eh-cont .eh-col {
    padding: 0px 0px 20px 0px;
  }
  .main-banner-subheading {
    font-size: 34px;
    line-height: 2rem;
  }
  .pd-usertype a {
    font-size: 14px;
  }
  .container-two {
    margin: 0px 0px;
    padding: 0px 10px;
  }
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: block;
    margin: 0px;
    padding: 10px 20px;
    background-color: #000;
  }
  .homepageBanner {
    margin-top: 133px;
  }
  ul.randd-cont-services li {
    font-size: 25px;
    width: 100%;
  }
  ul.randd-cont-services li img {
    margin-right: 15px;
    width: 52px;
    width: 52px;
  }

  .dos-cont .dos-heading {
    font-size: 17px;
    line-height: 1.5rem;
  }
  .dos-cont .dos-subheading {
    font-size: 12px;
    line-height: 1.5rem;
  }

  ul.dos li a {
    font-size: 10px;
    line-height: 1rem;
  }
  ul.dos li a img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  .footer-phone {
    font-size: 26px;
    padding-top: 15px;
  }
  .footer-company,
  .footer-list {
    font-size: 15px;
  }

  .fa-instagram,
  .fa-youtube,
  .fa-facebook {
    font-size: 28px !important;
  }
  .footer-heading {
    font-size: 20px !important;
  }
  .section-heading {
    font-size: 23px;
    line-height: 1.3rem;
    width: 95%;
  }
  .section-paragraph {
    font-size: 18px;
    width: 97%;
  }
  .licensedproducts-cont {
    margin: 10px 20px;
  }
  .accessories-cont {
    margin: 10px 0px 20px 0px;
  }
  .product-categories-cont {
    padding: 10px 0px 10px 0px;
    background: none !important;
  }
  .randd-cont {
    padding: 10px 0px 10px 0px;
  }
  .outstanding-cont {
    padding: 0px 0px 0px 0px;
    /*background: none;*/
  }
  .slick-slide {
    min-height: 465px !important;
  }
  .faq-cont {
    padding: 10px 0px 10px 0px;
  }
  .footer-address {
    font-size: 14px;
  }
  ul.randd-cont-services li {
    margin: 0px 0px 10px 0px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .swapimg-container img {
    width: 100%;
  }
  .cartwrap-left-five {
    width: 215px !important;
  }
  button.cart-btn span {
    font-size: 10px !important;
  }
  
  .product-supplier-cont .heading {
    font-size: 34px;
  }
  .lptcont-four {
    width: 600px;
    bottom: 70px;
    left: 20px;
    overflow: hidden;
  }
  .lptcont-four .section-heading {
    font-size: 30px;
  }
  .lptcont-four p.section-paragraph {
    font-size: 15px;
    overflow: hidden;
  }
  .lptcont-four p.section-paragraph p {
    margin: 0px;
  }
  .banner-v-center-hstext-bg .left {
    left: 35%;
  }
  .eh-cont .eh-col {
    padding: 0px 0px 20px 0px;
  }
  .pd-usertype a {
    font-size: 14px;
  }
  .container-two {
    margin: 0px 0px;
    padding: 0px 10px;
  }
  ul.dos li a {
    font-size: 16px;
    line-height: 1rem;
  }
  ul.dos li a img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  .homepageBanner {
    margin-top: 82px;
  }
}
@media screen and (min-width: 1401px) and (max-width: 1920px) {
  .swapimg-container img {
    width: 100%;
  }


  .lptcont-four {
    width: 600px;
    bottom: 70px;
    left: 20px;
    overflow: hidden;
  }
  .blackbutton {
    font-size: 16px;
    margin: 5px 0px;
    padding: 10px 60px;
  }
  .lptcont-four .section-heading {
    font-size: 36px;
  }
  .lptcont-four p.section-paragraph {
    font-size: 18px;
    overflow: hidden;
  }
  .lptcont-four p.section-paragraph p {
    margin: 0px;
  }
  .banner-v-center-hstext-bg .left {
    left: 32%;
  }
  .eh-cont .eh-col {
    padding: 0px 0px 20px 0px;
  }
  .container-two {
    margin: 0px 0px;
    padding: 0px 10px;
    position: relative;
    z-index: 9999;
  }
  ul.dos li a {
    font-size: 16px;
    line-height: 1rem;
  }
  ul.dos li a img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  .homepageBanner {
    margin-top: 82px;
  }
}
@media screen and (min-width: 1921px) and (max-width: 2560px) {
  .swapimg-container img {
    width: 100%;
  }

  .container-two {
    margin: 0px 0px;
    padding: 0px 10px;
  }
  ul.dos li a img {
    margin-right: 10px;
  }
}

.pagination-cont {
  margin-top: 20px !important;
}

.button2 {
  padding: 7px 8px;
  background-color: white;
  color: black;
  border: 2px solid #008cba;
}

.button2:hover {
  background-color: #008cba;
  color: white;
}

.modalLeft {
  padding: 20px;
}

/* Added to cart Css */

.success-msg-home-pdt {
  background: #008cba;
  border-radius: 15px;
  padding: 5px;
  text-align: center;
}

.success-msg,
.success-msg-other {
  position: absolute;
  margin: auto;
  top: 40%;
  left: 10%;
  z-index: 5;
  width: 80%;
  color: #fff;
  padding: 2px !important;
}

.product-default-block {
  position: relative;
}

.product-default-block .offer_label {
  top: 40px;
}

.offer_label {
  color: #fff;
  width: 175px;
  height: 22px;
  background: url(https://karactermania.com/kmania/images/offer_tag.png);
  position: absolute;
  background-size: 100%;
  background-repeat: no-repeat;
  top: 30px;
  right: -37px;
  z-index: 1;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  padding-top: 2px;
  font-size: 15px;
  padding-left: 5px;
  text-align: center;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
}

button[disabled] {
  background-color: #cccccc;
  color: black;
}

ul.pagination li.pagination__link--disabled:hover {
  background-color: white;
  color: white;
}

ul.pagination li.pagination__link--disabled a:hover {
  color: rgb(198, 197, 202);
}

button[disabled]:hover {
  background-color: #cccccc;
  color: black;
}
.text-center {
  font-size: 16px;
  text-align: center;
}

/* issue changs */
.cartwrap__block {
  display: flex;
  width: 100%;
}

.cartwrap__block .cartwrap__block__cart {
  width: 65%;
  float: none;
}

.cartwrap__block .cartwrap__block__cart .cart-btn {
  width: 100%;
  text-align: center;
}

.cartwrap__block .cartwrap__block__cart .cart-btn span {
  margin: 0px 0px 0px 8px;
}

.cartwrap__block .cartwrap__block__action {
  width: 35%;
  display: flex;
  justify-content: space-between;
}

.cartwrap__block .cartwrap__block__action .cartwrap-center {
  float: none;
  height: 36px;
  width: 50%;
}

.cartwrap__block .cartwrap__block__action .btn-black-two {
  width: 25%;
  float: none;
}

.cartwrap__block .cartwrap__block__action .cartwrap-center input[type="text"] {
  border-left: none !important;
  border-right: none !important;
  margin: 0;
  width: 100%;
  height: 36px;
}

.prod__grid__details .prod__details__desc {
  text-overflow: ellipsis;
  cursor: pointer;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 32px;
  padding-right: 5px !important;
}

.prod__grid__details .row .prod__details__brand {
  display: flex;
  align-items: center;
  padding: 0px;
}

.prod__grid__details .row .prod__details__sku,
.prod__grid__details .row .prod__details__reducedprice,
.prod__grid__details .row .prod__details__price {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.product__section .row .product__page__count,
.search__grid__section .row .product__page__count {
  width: 60%;
}

.product__section .row .grid__list__view,
.search__grid__section .row .grid__list__view {
  width: 40%;
  display: flex;
  justify-content: right;
  align-items: center;
}

.search__grid__section.row,
.search__grid__section.row > .row {
  margin: 0px;
}

.search__list__block {
  display: flex;
}

.search__list__block .search__list__imgblock {
  width: 16%;
}

.search__list__block .search__list__decblock {
  width: 84%;
}

.search__list__block .search__list__dec {
  width: 80%;
}

.cartwrap__list__block .cartwrap-center {
  height: 36px;
}

.cartwrap__list__block .cartwrap-center input {
  border-left: none;
  border-right: none;
  margin: 1px 0;
  width: 100%;
}

.cartwrap__list__block .btn-black-two {
  width: 25px;
}

.login__container {
  margin-top: 30px;
  padding: 15px;
}

@media (max-width: 1560px) {	
  .search__grid__section .search__grid__block {
    width: 20%;
  }

  .prod__grid__details .prod__details__desc {
    padding-right: 5px !important;
  }

  .search__list__block .search__list__imgblock {
    width: 20%;
  }

  .search__list__block .search__list__decblock {
    width: 80%;
  }

  .search__list__block .search__list__dec {
    width: 100%;
  }

  .product__section .row .product__grid__section {
    width: 33.33%;
  }
}

@media (max-width: 1360px) {
  .brand-child {
    font-size: 18px;
    line-height: 1.8rem;
  }

  .search__grid__section .search__grid__block {
    width: 25%;
  }

  .row.search__grid__section .row {
    padding: 0px;
  }
}

@media (max-width: 1320px) {
  .product__section .row .product__grid__section {
    width: 50%;
    margin-left: 0px !important;
  }

  .search__grid__container > .container {
    padding: 0px;
  }

  .product__list__block .product__list__imgblock {
    width: 35%;
  }

  .product__list__block .product__list__decblock {
    width: 65%;
  }

  .search__list__block .search__list__imgblock {
    width: 30%;
  }

  .search__list__block .search__list__decblock {
    width: 70%;
  }

  .search__list__block .search__list__dec {
    width: 100%;
  }
}

@media (max-width: 780px) {
  .outstanding-cont-two .slick-slider,
  .outstanding-cont .slick-slider {
    width: 100% !important;
  }

  .brand-child {
    font-size: 16px;
    line-height: 1.5rem;
  }

  .cardmargins-bg.prod__grid__block {
    width: 90%;
  }

  .cartwrap__block .cartwrap__block__cart {
    width: 60%;
  }

  .cartwrap__block .cartwrap__block__action {
    width: 40%;
  }

  ul.randd-cont-services li {
    padding: 0;
  }

  .search__grid__section .search__grid__block {
    width: 33.33%;
  }

  .pagination-cont {
    justify-content: center;
    display: flex;
  }

  .product__section .product__grid__row {
    margin: 0;
  }

  .product__list__block .product__list__imgblock,
  .search__list__block .search__list__imgblock {
    width: 45%;
  }

  .product__list__block .product__list__decblock,
  .search__list__block .search__list__decblock {
    width: 55%;
  }
}

@media (max-width: 575px) {
  .outstanding-cont-two .slick-slider,
  .outstanding-cont .slick-slider {
    width: 75% !important;
  }

  .homepageBanner {
    margin-top: 154px;
  }

  .brand-child {
    font-size: 20px;
    line-height: 1.5rem;
  }

  .search__grid__section .search__grid__block {
    width: 50%;
  }

  .row.search__grid__section .row {
    padding: 0 12px;
  }

  .product__list__block .product__list__imgblock,
  .product__list__block .product__list__decblock,
  .search__list__block .search__list__imgblock,
  .search__list__block .search__list__decblock {
    width: 100%;
  }

  .footer-phone {
    font-size: 28px;
  }

  .footer-mid .container {
    padding: 0px;
  }
}

@media (max-width: 483px) {
  .homepageBanner {
    margin-top: 171px;
  }

  .product__section .row .product__grid__section,
  .search__grid__section .search__grid__block {
    width: 100%;
  }

  .search__grid__section .row .grid__list__view {
    padding: 0;
  }
}

@media (max-width: 430px) {
  .outstanding-cont-two .slick-slider,
  .outstanding-cont .slick-slider {
    width: 90% !important;
  }

  .prod__grid__block {
    width: 92%;
  }

  .randd-cont .randd-width {
    width: 85%;
  }

  ul.dos li {
    padding: 15px;
  }

  ul.dos li a {
    font-size: 16px;
  }
}
/* End issue changs */
