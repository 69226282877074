.resetpassword__section {
  margin-top: 86px;
}

.resetpassword__block {
  width: 35%;
  padding: 9em 0;
  margin: 0 auto;
}

.resetpassword__block .card {
  padding: 65px 20px;
}

.resetpassword__block h1 {
  color: #0da5e8;
  text-align: center;
  font-size: 28px;
}

.resetpassword__block .para {
  text-align: center;
  margin-bottom: 25px;
}


@media (max-width: 1440px) { 
  .resetpassword__block {
    width: 40%;
    padding: 6em 0;
  }
}

@media (max-width: 1030px) {
  .resetpassword__section {
      margin-top: 138px;
  }

  .resetpassword__block {
    width: 50%;
  }
}

@media (max-width: 991px) {
  .resetpassword__section {
      margin-top: 154px;
  }

  .resetpassword__block {
    width: 60%;
  }

  .resetpassword__block .card {
    padding: 40px 20px;
  }
}

@media (max-width: 580px) {
  .resetpassword__block {
    width: 92%;
  }
}