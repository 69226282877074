.plp_licence {
  font-size: 12px !important;
}

.dropdownLicense {
  border: 1px solid lightgray;
}

.plp__producttype {
  list-style: none;
  padding-top: 0;
  font-size: 12px;
}

.plp__producttype li {
  cursor: pointer;
  font-size: 12px;
}

.plp__cluster {
  position: relative;
  list-style: none;
}

.plp__cluster:hover {
  background-color: #0da5e8 !important;
}

.plp__clusterBrands {
  list-style: none;
  position: absolute;
  right: 0em;
  left: 13.2em;
  top: 0;
  padding: 0;
  border: 1px solid lightgray;
  background-color: white;
  border-radius: 4px;
  width: max-content;
  font-size: 12px;
  max-height: 30vh;
  overflow: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: thin;
}

.plp__clusterBrands .plp__selectall {
  color: #0da5e8;
  font-size: 14px;
  border-bottom: 1px solid #0da5e8;
  font-weight: 500;
}
.plp__clusterBrands .plp__selectall:hover {
  /* background-color: white; */
  color: white;
}

.plp__clusterBrands::-webkit-scrollbar {
  width: 4px;
}
.plp__clusterBrands::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}
.plp__clusterBrands::-webkit-scrollbar-thumb {
  background-color: #0da5e8;
  outline: 1px solid slategrey;
}

.plp__clusterBrands li {
  padding: 4px 16px;
  word-break: break-all;
  font-size: 12px;
}
.plp__clusterBrands li:hover {
  background-color: #0da5e8;
  cursor: pointer;
}

.selected {
  display: flex !important;
  align-items: center;
  justify-content: space-between !important;
  /* background-color: #0da5e8; */
}

.selected svg {
  height: 16px;
  width: 16px;
  border-radius: 100%;
  background-color: white;
  color: #0da5e8;
}
