@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@400;500;600;700;800;900&amp;display=swap");

* {
  font-family: "Lexend", sans-serif;
}
/* banner sec start */
#karac-banner-sec {
  position: relative;
}
#karac-banner-sec .container {
  position: absolute;
  top: 35%;
  left: 9%;
}
#karac-banner-sec .container .karac-banner-text h2 {
  font-size: 60px;
  font-weight: 600;
  text-transform: uppercase;
  color: #ffffff;
}
#karac-banner-sec .container .karac-banner-text .karac-inner-text {
  width: 545px;
  padding: 10px;
  background-color: #ffffff4a;
}
#karac-banner-sec .container .karac-banner-text p {
  font-size: 30px;
  font-weight: 500;
  line-height: 35px;
  margin-bottom: 0;
  color: #fff;
}
#karac-banner-below-sec i.fa-caret-left {
  position: absolute;
  left: -25px;
  bottom: 45%;
  font-size: 25px;
}
#karac-banner-below-sec i.fa-caret-right {
  position: absolute;
  right: -25px;
  bottom: 45%;
  font-size: 25px;
}
/* banner sec end */
/* banner below white sec start */
#karac-banner-below-sec {
  padding: 5% 0;
  background-color: #ffffff;
}
#karac-banner-below-sec .below-left p {
  color: #000000;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  padding-right: 30px;
}
#karac-banner-below-sec .below-right p {
  color: #000000;
  font-size: 40px;
  font-weight: 800;
  line-height: 48px;
  text-transform: uppercase;
  border-bottom: 6px solid #000;
  padding-bottom: 10%;
}
#karac-banner-below-sec .container .karac-row2-p p {
  margin: 0;
  font-size: 26px;
  font-weight: 800;
  color: #000000;
  line-height: 30px;
}
#karac-banner-below-sec .container .karac-row2-h2 h2 {
  padding: 20px;
  margin: 0;
  background-color: #0da5e8;
  text-align: center;
  font-size: 40px;
  font-weight: 800;
  color: #000;
}
/* banner below white sec end */
/* banner below blue sec start */
#karac-banner-below-blue-sec {
  padding: 5% 0;
  background-color: #0da5e8;
}
#karac-banner-below-blue-sec .karac-blue-left h2 {
  font-size: 50px;
  font-weight: 800;
  margin-bottom: 28px;
}
#karac-banner-below-blue-sec .karac-blue-left p {
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
}
#karac-banner-below-blue-sec .karac-blue-right .karac-left-para p {
  margin: 0;
  font-size: 24px;
  font-weight: 800;
  color: #000000;
  line-height: 27px;
  text-transform: uppercase;
}
#karac-banner-below-blue-sec .karac-blue-right .col-md-12 hr {
  opacity: 1;
  border-top: 5px solid #000;
  margin-bottom: 38px;
}
#karac-banner-below-blue-sec .karac-blue-right .karac-right-btn h2 {
  padding: 25px;
  margin: 0;
  background-color: #fff;
  text-align: center;
  font-size: 40px;
  font-weight: 800;
  color: #000;
  text-transform: uppercase;
}
/* banner below blue sec end */
/* karac-gray-sec start */
#karac-gray-sec {
  padding: 5% 0;
  background-color: lightgray;
}
#karac-gray-sec .container .col-md-12 hr {
  opacity: 1;
  border-top: 5px solid #000;
  margin-bottom: 38px;
}
#karac-gray-sec .karac-gray-left h2 {
  font-size: 50px;
  font-weight: 800;
  margin-bottom: 28px;
}
#karac-gray-sec .karac-gray-left p {
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
}
#karac-gray-sec .karac-gray-right .solicitar p {
  margin: 0;
  font-size: 28px;
  font-weight: 800;
  color: #000000;
  line-height: 32px;
  text-transform: uppercase;
}
#karac-gray-sec .karac-gray-right .solicitar-btn h2 {
  padding: 24px;
  margin: 0;
  background-color: #ffffff;
  text-align: center;
  font-size: 40px;
  font-weight: 800;
  color: #000;
  text-transform: uppercase;
}
/* karac-gray-sec end */
/* karac-register-sec start */
#karac-register-sec {
  padding: 5% 0;
}
#karac-register-sec .karac-reg-left h2 {
  font-size: 45px;
  font-weight: 800;
  margin-bottom: 28px;
}
#karac-register-sec .karac-reg-left p {
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
}
#karac-register-sec .karac-reg-right img {
  height: 369px;
  object-fit: cover;
}
#karac-register-sec .karac-reg-right .col-md-12 hr {
  opacity: 1;
  border-top: 5px solid #000;
  margin-bottom: 38px;
}
#karac-register-sec .karac-reg-right-img img {
  height: 276px;
  object-fit: cover;
}
#karac-register-sec .karac-reg-right .karac-reg-right-btn .right-btn1 p {
  margin: 0;
  background-color: #ffffff;
  font-size: 30px;
  font-weight: 800;
  line-height: 38px;
  color: #000;
  align-items: center;
  display: flex;
}
#karac-register-sec .karac-reg-right .karac-reg-right-btn .right-btn2 p {
  padding: 25px;
  margin: 0;
  background-color: #0da5e8;
  text-align: center;
  font-size: 40px;
  font-weight: 800;
  color: #000;
}
/* karac-register-sec end */
/* karac-lifestyle-sec start*/
#karac-lifestyle-sec {
  padding: 7% 0 6%;
  background-color: #b8b8b8;
}
#karac-lifestyle-sec .karac-lifestyle-head h2 {
  font-size: 40px;
  font-weight: 800;
  color: #000;
  text-transform: uppercase;
  border-top: 5px solid #000;
  padding: 15px 0;
}
/* karac-lifestyle-sec end*/
/* karac-trust-sec start */
#karac-trust-sec {
  padding: 5% 0;
}
#karac-trust-sec .container h2 {
  font-size: 40px;
  font-weight: 800;
  color: #000;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 50px;
}
#karac-trust-sec .karac-grid {
  width: 312px;
  height: 306px;
  background-color: #000;
}
#karac-trust-sec .karac-grid p {
  color: #ffffff;
  padding: 20px;
  width: 165px;
  color: #ffffff;
  padding: 20px;
  font-size: 18px;
}
#karac-trust-sec .container .row .karac-trust1 p {
  font-size: 40px;
  font-weight: 800;
  color: #000;
  text-transform: uppercase;
  text-align: center;
  line-height: 42px;
}
#karac-trust-sec .container .row .karac-trust2 p {
  font-size: 40px;
  font-weight: 800;
  background-color: #0da5e8;
  color: #000;
  text-transform: uppercase;
  text-align: center;
  line-height: 42px;
  padding: 16px 22px;
}
/* karac-trust-sec end */
